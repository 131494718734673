import React, { useState, useRef } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";
import { FiUsers } from "react-icons/fi";
import { FiBarChart2 } from "react-icons/fi";

import {
  Bars3Icon,
  XMarkIcon,
  UserIcon,
  ClockIcon,
  ChartBarIcon,
  CogIcon, // Icon for Overall Analysis
  ArrowRightOnRectangleIcon, // Icon for Logout
} from "@heroicons/react/24/outline"; // Heroicons v2
import logo from "../img/logo.png";

function Dashboard() {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  // Function to close the sidebar
  const handleLinkClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  // Logout functionality
  const handleLogout = () => {
    // Clear all items from localStorage
    localStorage.clear();

    // Redirect to the login page
    navigate("/login");
  };

  return (
    <div className="relative min-h-screen">
      {/* Sidebar */}
      <style>
        {`
          @media print {
            .sidebar {
              display: none;
            }
            .content {
              margin-left: 0 !important;
            }
          }
        `}
      </style>
      <div
        ref={sidebarRef}
        className={`sidebar fixed top-0 left-0 h-full bg-gradient-to-r from-[#3a97d2] via-[#2b7d99] to-[#1f5f77] p-4 flex flex-col items-center transition-transform duration-500 ease-in-out text-white shadow-lg z-50 ${
          isOpen ? "w-64 fixed" : "w-20 fixed"
        }`}
      >
        {/* Toggle button */}
        {!isOpen && (
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white focus:outline-none mb-6"
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
        )}
        {/* Sidebar Header */}
        <div className="flex flex-col items-center mb-8">
          {/* Company Logo */}
          {isOpen && (
            <img
              src={logo}
              alt="Your Company Logo"
              className={`h-20 w-64 object-contain transition-transform duration-700 ease-in-out transform ${
                isOpen ? "scale-100 opacity-100" : "scale-90 opacity-0"
              }`}
            />
          )}

          <h1
            className={`text-xl font-extrabold transition-opacity duration-500 ease-in-out bg-gradient-to-r from-white via-gray-300 to-blue-300 inline-block text-transparent bg-clip-text ${
              isOpen ? "opacity-100" : "opacity-0"
            }`}
          >
            {/* You can put your title here */}
          </h1>
        </div>

        {/* Navigation Links */}
        <nav className="space-y-4">
          {/* <Link
            to="/dashboard/project-analysis"
            onClick={handleLinkClick} // Close sidebar on link click
            className="flex items-center space-x-4  p-3 rounded-md transition-all duration-300 ease-in-out font-semibold text-lg transform hover:scale-105"
          >
            <ChartBarIcon className="h-6 w-6" />
            {isOpen && (
              <span className="transition-opacity duration-300">
                Project Analysis
              </span>
            )}
          </Link> */}

          <Link
            to="/dashboard/user-analysis"
            onClick={handleLinkClick} // Close sidebar on link click
            className="flex items-center space-x-4  p-3 rounded-md transition-all duration-300 ease-in-out font-semibold text-lg transform hover:scale-105"
          >
            <UserIcon className="h-6 w-6" />
            {isOpen && (
              <span className="transition-opacity duration-300">
                User Analysis
              </span>
            )}
          </Link>

          <Link
            to="/dashboard/overall-analysis"
            onClick={handleLinkClick} // Close sidebar on link click
            className="flex items-center space-x-4 hover: p-3 rounded-md transition-all duration-300 ease-in-out font-semibold text-lg transform hover:scale-105"
          >
            <CogIcon className="h-6 w-6" />
            {isOpen && (
              <span className="transition-opacity duration-300">
                Overall Analysis
              </span>
            )}
          </Link>
          <Link
            to="/dashboard/attendance"
            onClick={handleLinkClick} // Close sidebar on link click
            className="flex items-center space-x-4  p-3 rounded-md transition-all duration-300 ease-in-out font-semibold text-lg transform hover:scale-105"
          >
            <ClockIcon className="h-6 w-6" />
            {isOpen && (
              <span className="transition-opacity duration-300">
                Attendance
              </span>
            )}
          </Link>
          <Link
            to="/dashboard/user-attendance"
            onClick={handleLinkClick} // Close sidebar on link click
            className="flex items-center space-x-4 p-3 rounded-md transition-all duration-300 ease-in-out font-semibold text-lg transform hover:scale-105"
          >
            <FiUsers className="h-6 w-6" />
            {isOpen && (
              <span className="transition-opacity duration-300">
                User-Attendance
              </span>
            )}
          </Link>
          <Link
            to="/dashboard/detailed-utilization"
            onClick={handleLinkClick} // Close sidebar on link click
            className="flex items-center space-x-4 p-3 rounded-md transition-all duration-300 ease-in-out font-semibold text-lg transform hover:scale-105"
          >
            <FiBarChart2 className="h-6 w-6" />
            {isOpen && (
              <span className="transition-opacity duration-300">
                Utilization
              </span>
            )}
          </Link>

          {/* Logout Link */}
          <button
            onClick={handleLogout}
            className="flex items-center space-x-4 p-3 rounded-md transition-all duration-300 ease-in-out font-semibold text-lg transform hover:scale-105"
          >
            <ArrowRightOnRectangleIcon className="h-6 w-6" />
            {isOpen && (
              <span className="transition-opacity duration-300">Logout</span>
            )}
          </button>
        </nav>
      </div>

      {/* Dimming background overlay */}
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-500 ease-in-out z-40 ${
          isOpen ? "opacity-50 visible" : "opacity-0 invisible"
        }`}
        onClick={() => setIsOpen(false)} // Close sidebar when clicking outside
      ></div>

      {/* Main Content */}
      <div
        className={`content flex-1 p-6 transition-all duration-500 ease-in-out ${
          isOpen ? "ml-0" : "ml-20"
        } bg-gray-100 min-h-screen relative`}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;

import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { HiOutlineUser } from "react-icons/hi";
const HeaderToolbar = ({
  selectedOption,
  handleOptionChange,
  startDate,
  handleDateChange,
  selectedUser,
  handleUserChange,
  activeUsers,
  adjustDates,
  handleExportCSV,
  timesheetData,
}) => {
  return (
    <div className="flex flex-col justify-center text-center ">
      <h1 className=" bg-white py-10 text-3xl font-extrabold">User Analysis</h1>

      <div className="sticky top-0 bg-white z-10 flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 mb-6 p-4">
        {/* Date Range Dropdown */}

        <FormControl style={{ width: "100%", maxWidth: "150px" }}>
          <InputLabel id="date-range-label">Select Range</InputLabel>
          <Select
            labelId="date-range-label"
            id="date-range"
            label="Select Range"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </Select>
        </FormControl>

        {/* Conditionally Render DatePicker */}
        <div style={{ flex: 1 }}>
          {selectedOption === "month" ? (
            <DatePicker
              views={["year", "month"]}
              label="Select Month"
              value={startDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          ) : (
            <DatePicker
              label="Select Date"
              value={startDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          )}
        </div>

        {/* User Selection */}
        <FormControl style={{ width: "100%", maxWidth: "400px" }}>
          <InputLabel id="user-select-label">Select User</InputLabel>
          <Select
            labelId="user-select-label"
            className="capitalize"
            value={selectedUser || ""}
            onChange={handleUserChange}
            label="Select User"
          >
            {activeUsers?.map((user) => (
              <MenuItem key={user._id} value={user._id}>
                <div className="flex items-center space-x-2">
                  <HiOutlineUser style={{ fontSize: 18, color: "#4a4a4a" }} />
                  {/* User icon */}
                  <span>{`${user?.fname} ${user?.lname}`}</span>{" "}
                  {/* User name */}
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Navigation Buttons */}
        <div className="flex space-x-2">
          <Button variant="outlined" onClick={() => adjustDates("prev")}>
            Previous
          </Button>
          <Button variant="outlined" onClick={() => adjustDates("next")}>
            Next
          </Button>
        </div>

        {/* Export CSV Button */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleExportCSV}
          disabled={!selectedUser}
        >
          Export CSV
        </Button>
      </div>
    </div>
  );
};

export default HeaderToolbar;

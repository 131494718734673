import React, { useState, useRef, useEffect } from 'react';
import Chart from 'react-apexcharts';

import { motion } from 'framer-motion'; // For smooth animations
import {
  getShortNameForProjects,
  getShortNameForTask,
  getMediumNameForProjects,
  getMediumNameForTask,
} from '../utensils/simplifier';
import {
  Button,
  Container,
  Paper,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { styled } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchDataByDateRange } from '../api/api1';
import getPreviousWeek from '../utensils/prevWeek';

const StyledContainer = styled(Container)({
  backgroundColor: '#FFFFFF',
  padding: '30px',
  borderRadius: '16px',
  boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1)',
  maxWidth: '100%',
});

const Title = styled('h1')({
  textAlign: 'center',
  color: '#0d47a1',
  marginBottom: '30px',
  fontSize: '2.5rem',
  fontFamily: 'Roboto, sans-serif',
});
const NavBar = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#f5f5f5',
  padding: '15px 30px',
  borderRadius: '8px',
  marginBottom: '20px',
});

const StyledLine = styled('hr')({
  border: 'none',
  borderTop: '2px solid #ccc',
  margin: '20px 0',
});

const ChartContainer = styled(Paper)({
  marginTop: '20px',
  padding: '20px',
  borderRadius: '12px',
  backgroundColor: '#f8f9fa',
  textAlign: 'center',
});

const OverallAnalysis = () => {
  const { start, end } = getPreviousWeek();
  const [projectData, setProjectData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end); // New state for end date
  const [viewType, setViewType] = useState('week');
  const [loading, setLoading] = useState(false);
  const [showGraphs, setShowGraphs] = useState(false);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [selectedView, setSelectedView] = useState('project');
  const [overallData, setOverallData] = useState([]);

  const handleViewChange = (event) => {
    setViewType(event.target.value);
    setStartDate(null); // Reset the date selection when view type changes
    setShowGraphs(false);
  };

  const handleSubmit = async () => {
    if (!startDate || (viewType === 'custom' && !endDate)) return; // Ensure both startDate and endDate for custom range

    let minDate, maxDate;
    if (viewType === 'day') {
      minDate = startDate.toDateString();
      maxDate = startDate.toDateString();
    } else if (viewType === 'week') {
      const startOfWeek = new Date(startDate);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      minDate = startOfWeek.toDateString();
      maxDate = endOfWeek.toDateString();
    } else if (viewType === 'month') {
      const startOfMonth = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        0
      );
      minDate = startOfMonth.toDateString();
      maxDate = endOfMonth.toDateString();
    } else if (viewType === 'custom') {
      minDate = startDate.toDateString();
      maxDate = endDate.toDateString(); // Use both start and end dates for custom range
    }

    setMinDate(minDate);
    setMaxDate(maxDate);

    setLoading(true);
    try {
      const data = await fetchDataByDateRange(minDate, maxDate);
      if (data) {
        setOverallData(data);
        const projectDistribution = {};
        const taskDistribution = {};
        Object.keys(data).forEach((projectId) => {
          const project = data[projectId];
          const projectName = project.projectName;
          if (!projectDistribution[projectName]) {
            projectDistribution[projectName] = 0;
          }
          Object.keys(project.users).forEach((userId) => {
            const user = project.users[userId];
            Object.keys(user.hours).forEach((taskId) => {
              const task = user.hours[taskId];
              const taskName = task.name;
              const hours = task.hour;
              projectDistribution[projectName] += hours;
              if (!taskDistribution[taskName]) {
                taskDistribution[taskName] = 0;
              }
              taskDistribution[taskName] += hours;
            });
          });
        });
        setProjectData(
          Object.entries(projectDistribution).filter(
            ([name, hours]) => name && !isNaN(hours)
          )
        );
        setTaskData(
          Object.entries(taskDistribution).filter(
            ([name, hours]) => name && !isNaN(hours)
          )
        );
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
    setShowGraphs(true);
  };

  const handleExport = () => {
    const escapeCommas = (value) => {
      if (typeof value === 'string' && value.includes(',')) {
        return `"${value}"`; // Enclose the value with double quotes if it contains commas
      }
      return value;
    };

    const formatDate = (date) => {
      const d = new Date(date);
      const month = d.toLocaleString('default', { month: 'short' }); // "Jun" for June
      const day = String(d.getDate()).padStart(2, '0'); // "01" for day 1
      const year = d.getFullYear(); // 2024
      return `${month}_${day}_${year}`;
    };

    const overallAnalysisHeading = 'Overall Analysis Report\n\n';
    const projectSectionHeader = 'Projects\nName,Hours\n';
    const projectCsv = projectData
      .map(([name, hours]) => `${escapeCommas(name)},${hours}`)
      .join('\n');

    const taskSectionHeader = '\n\nTasks\nName,Hours\n';
    const taskCsv = taskData
      .map(([name, hours]) => `${escapeCommas(name)},${hours}`)
      .join('\n');

    const csvContent =
      overallAnalysisHeading +
      projectSectionHeader +
      projectCsv +
      taskSectionHeader +
      taskCsv;

    const formattedMinDate = minDate ? formatDate(minDate) : 'start-date';
    const formattedMaxDate = maxDate ? formatDate(maxDate) : 'end-date';
    const fileName = `OverallAnalysis_${formattedMinDate}_to_${formattedMaxDate}.csv`;

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const calculateTotalHours = (data) => {
    // Initialize total hours to 0
    let totalHours = 0;

    // Loop through each entry in the data
    data.forEach((entry) => {
      // entry[1] represents the hours, add it to the total
      totalHours += entry[1];
    });

    return totalHours;
  };

  const totalHours = calculateTotalHours(projectData);

  console.log(taskData);
  console.log(projectData);
  return (
    <StyledContainer>
      <Title>
        <h1 className="sticky top-0 mb-4 text-3xl font-extrabold text-gray-900 dark:text-black md:text-5xl lg:text-6xl">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
            Overall
          </span>{' '}
          Analysis
        </h1>
        <div
          style={{ borderBottom: '2px solid #0d47a1', marginBottom: '20px' }}
        ></div>
      </Title>
      {/* <div style={{ borderBottom: '2px solid #0d47a1', marginBottom: '20px' }}></div> */}
      {/* <NavBar className="sticky top-0 bg-white z-10"> */}
      <div
        className="sticky top-0 bg-white z-10 flex flex-wrap md:flex-nowrap justify-between w-full flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 mb-6 p-4"
        style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
      >
        {/* <div className="flex flex-wrap md:flex-nowrap justify-between w-full"> */}
        {/* Left section for select range and date pickers */}
        <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
          <FormControl variant="outlined" style={{ minWidth: 150 }}>
            <InputLabel>View Type</InputLabel>
            <Select
              value={viewType}
              onChange={handleViewChange}
              label="View Type"
            >
              {/* <MenuItem value="day">Day</MenuItem> */}
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {viewType === 'custom' ? (
              <>
                {/* Show both start and end date pickers for custom view */}
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </>
            ) : (
              <DatePicker
                label={
                  viewType === 'month'
                    ? 'Select Month'
                    : viewType === 'week'
                    ? 'Select Week'
                    : 'Select Day'
                }
                views={
                  viewType === 'month'
                    ? ['year', 'month']
                    : ['year', 'month', 'day']
                }
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            )}
          </LocalizationProvider>
        </div>

        {/* Right section for the buttons */}
        <div className="flex items-center space-x-4 ml-auto">
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            style={{
              backgroundColor: '#007bff',
              padding: '10px 20px',
              fontWeight: 'bold',
            }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Submit'
            )}
          </Button>
          <Button
            onClick={handleExport}
            variant="contained"
            color="secondary"
            style={{
              padding: '10px 20px',
              fontWeight: 'bold',
              backgroundColor: '#ff4081',
            }}
          >
            Export CSV
          </Button>
        </div>
      </div>
      {/* </NavBar> */}

      {showGraphs && (
        <>
          <h1 className="flex justify-center items-center text-5xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
            Project Distribution
          </h1>

          <ChartContainer style={{ maxWidth: '800px', margin: '0 auto' }}>
            <Chart
              options={{
                chart: {
                  type: 'donut',
                  width: '100%',
                  height: '40vh',
                },
                // Legend displays short project names
                labels: projectData.map((d) =>
                  getShortNameForProjects(d[0] || 'Unknown Project')
                ),
                colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'dark',
                    type: 'vertical',
                    gradientToColors: [
                      '#00E396',
                      '#FEB019',
                      '#FF4560',
                      '#775DD0',
                      '#008FFB',
                    ],
                    stops: [0, 100],
                  },
                },
                legend: {
                  position: 'bottom',
                  horizontalAlign: 'center',
                  fontSize: '14px',
                  itemMargin: { horizontal: 10, vertical: 5 },
                },
                dataLabels: {
                  enabled: true,
                },
                tooltip: {
                  enabled: true,
                  // Tooltip shows medium project names
                  y: {
                    formatter: function (value, { seriesIndex }) {
                      const mediumName = getMediumNameForProjects(
                        projectData[seriesIndex][0]
                      ); // Get medium project name
                      return `${mediumName}: ${value} hours`;
                    },
                  },
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: '65%',
                      labels: {
                        show: true,
                        total: {
                          show: true,
                          label: 'Total',
                          formatter: () =>
                            projectData
                              .reduce((sum, val) => sum + val[1], 0)
                              .toFixed(2),
                        },
                      },
                    },
                  },
                },
              }}
              series={projectData.map((d) => d[1] || 0)}
              type="donut"
            />
          </ChartContainer>

          <h1 className="flex justify-center items-center text-5xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
            Task Distribution
          </h1>

          <ChartContainer style={{ maxWidth: '800px', margin: '0 auto' }}>
            <Chart
              options={{
                chart: {
                  type: 'donut',
                  width: '85%',
                  height: '40vh',
                },
                // Legend displays short task names
                labels: taskData.map((d) =>
                  getShortNameForTask(d[0] || 'Unknown Task')
                ),
                colors: ['#775DD0', '#008FFB', '#00E396', '#FEB019', '#FF4560'],
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'dark',
                    type: 'vertical',
                    gradientToColors: [
                      '#00E396',
                      '#FEB019',
                      '#FF4560',
                      '#775DD0',
                      '#008FFB',
                    ],
                    stops: [0, 100],
                  },
                },
                legend: {
                  position: 'bottom',
                  horizontalAlign: 'center',
                  fontSize: '14px',
                  itemMargin: { horizontal: 10, vertical: 5 },
                },
                dataLabels: {
                  enabled: true,
                },
                tooltip: {
                  enabled: true,
                  // Tooltip shows medium task names
                  y: {
                    formatter: function (value, { seriesIndex }) {
                      const mediumName = getMediumNameForTask(
                        taskData[seriesIndex][0]
                      ); // Get medium task name
                      return `${mediumName}: ${value} hours`;
                    },
                  },
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: '65%',
                      labels: {
                        show: true,
                        total: {
                          show: true,
                          label: 'Total',
                          formatter: () =>
                            taskData
                              .reduce((sum, val) => sum + val[1], 0)
                              .toFixed(2),
                        },
                      },
                    },
                  },
                },
              }}
              series={taskData.map((d) => d[1] || 0)}
              type="donut"
            />
          </ChartContainer>
        </>
      )}
      <div className="p-4">
        {/* Display Total Hours if there is data */}
        {Object.keys(projectData).length > 0 && (
          <div className="mt-4 p-4 flex justify-center">
            <h2 className="sticky top-0 mb-4 text-3xl font-bold text-gray-900 dark:text-black md:text-5xl lg:text-5xl">
              <span className="text-transparent bg-clip-text bg-gray-900">
                Overall Time:
              </span>{' '}
              {totalHours.toFixed(2)} hours
            </h2>
            {/* <p className="text-3xl font-bold ml-2 text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
      {totalHours.toFixed(2)} hours
    </p> */}
          </div>
        )}
        {/* Button Group */}
        {/* <div className="flex justify-center mb-4">
        <button
          className={`mr-4 px-6 py-2 ${selectedView === "project" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setSelectedView("project")}
        >
          Project View
        </button>
        <button
          className={`px-6 py-2 ${selectedView === "task" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setSelectedView("task")}
        >
          Task View
        </button>
      </div> */}

        {/* Conditionally render views based on selectedView */}
        {overallData && Object.keys(overallData).length > 0 ? (
          <ProjectViewButtonTable overallData={overallData} />
        ) : (
          <p></p>
        )}

        {/* {selectedView === 'task' && taskData && Object.keys(taskData).length > 0 ? (
        <TaskViewTable data={taskData} />
      ) : (
        <p>No task data available.</p>
      )} */}
      </div>
      {/* <div className="container mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">Overall Analysis</h1>
      {overallData? (
        <ProjectViewButtonTable overallData={overallData} />
      ) : (
        <div>Loading data...</div>
      )}
    </div> */}
    </StyledContainer>
  );
};

export default OverallAnalysis;

const ProjectViewTable = ({ data }) => {
  // Guard clause to check if data is undefined or empty
  if (!data || data.length === 0) {
    return <p>Loading project data...</p>;
  }

  return (
    <div className="p-4">
      {data.map((project, index) => (
        <div key={index} className="mb-8">
          <h2 className="text-xl font-semibold mb-4">{project[0]}</h2>
          <div className="mb-4">
            <table className="min-w-full table-auto border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Project Name
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Hours
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="border border-gray-300 px-4 py-2">
                    {project[0]}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {project[1]}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

const TaskViewTable = ({ data }) => {
  // Guard clause for undefined data
  if (!data || data.length === 0) {
    return <p>No task data available.</p>;
  }

  const tasksMap = {};
  const userHoursMap = {};

  data.forEach((project) => {
    project?.users?.forEach((user) => {
      user?.hours?.forEach((task) => {
        // Create tasks map
        if (!tasksMap[task?.task_id]) {
          tasksMap[task?.task_id] = {
            name: task?.name,
            fullName: task?.name,
            hours: 0,
          };
        }
        tasksMap[task?.task_id].hours += parseFloat(task?.hour || 0);

        // Create user hours map
        if (!userHoursMap[user?.uid]) {
          userHoursMap[user?.uid] = { name: user?.name, tasks: {}, total: 0 };
        }
        userHoursMap[user?.uid].tasks[task?.task_id] =
          (userHoursMap[user?.uid]?.tasks[task?.task_id] || 0) +
          parseFloat(task?.hour || 0);
        userHoursMap[user?.uid].total += parseFloat(task?.hour || 0);
      });
    });
  });

  const taskIds = Object.keys(tasksMap);
  const taskNames = taskIds.map((taskId) => tasksMap[taskId]);

  return (
    <div className="p-4 overflow-x">
      <h2 className="text-xl font-semibold mb-4">Task Distribution</h2>
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left">Name</th>
            <th className="border border-gray-300 px-4 py-2 text-left bg-yellow-200 font-bold">
              Total
            </th>
            {taskNames.map((task, index) => (
              <th
                key={index}
                className="border border-gray-300 px-4 py-2 text-left"
              >
                <div className="tooltip">
                  {task?.name}
                  <span className="tooltiptext">{task?.fullName}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(userHoursMap).map((userId) => {
            const user = userHoursMap[userId];
            return (
              <tr key={userId} className="bg-white odd:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2">
                  {user?.name}
                </td>
                <td className="border border-gray-300 px-4 py-2 bg-yellow-200 font-bold">
                  {user?.total?.toFixed(2)}
                </td>
                {taskIds.map((taskId) => (
                  <td key={taskId} className="border border-gray-300 px-4 py-2">
                    {user?.tasks?.[taskId] || 0}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ProjectViewButtonTable = ({ overallData }) => {
  const [expandedProject, setExpandedProject] = useState(null);
  const [expandedUser, setExpandedUser] = useState(null);
  const tableRef = useRef(null);

  // Toggle expansion for specific project
  const toggleExpandProject = (projectId) => {
    setExpandedProject(expandedProject === projectId ? null : projectId);
    setExpandedUser(null);
  };

  // Toggle expansion for specific user
  const toggleExpandUser = (userId) => {
    setExpandedUser(expandedUser === userId ? null : userId);
  };

  // Click outside table to collapse expanded rows
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        setExpandedProject(null);
        setExpandedUser(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tableRef]);

  return (
    <div className="p-6">
      <div ref={tableRef} className="overflow-x-auto w-full">
        <table className="min-w-full table-auto border-collapse border border-gray-300 shadow-md rounded-lg text-sm">
          <thead>
            <tr className="bg-gradient-to-r from-indigo-600 to-purple-500 text-white rounded-t-lg">
              <th className="border border-gray-300 px-4 py-2 text-left rounded-tl-lg w-3/4">
                Project Name
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left rounded-tr-lg w-1/4">
                Total Hours
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(overallData).map((projectId) => {
              const project = overallData[projectId];
              const totalHours = Object.values(project.users)
                .flatMap((user) => Object.values(user.hours))
                .reduce((sum, task) => sum + task.hour, 0);

              return (
                <React.Fragment key={projectId}>
                  {/* Project Row */}
                  <tr
                    className={`cursor-pointer bg-gray-50 hover:bg-purple-100 transition duration-300 ${
                      expandedProject === projectId ? 'bg-purple-200' : ''
                    }`}
                    onClick={() => toggleExpandProject(projectId)}
                  >
                    <td className="border border-gray-300 px-6 py-3 font-medium">
                      {project.projectName}
                    </td>
                    <td className="border border-gray-300 px-6 py-3">
                      {totalHours.toFixed(2)}
                    </td>
                  </tr>

                  {/* Expandable Row for Users */}
                  {expandedProject === projectId && (
                    <tr>
                      <td colSpan="2" className="p-0">
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          transition={{ duration: 0.2 }}
                          className="p-4 bg-gray-100 rounded-lg"
                        >
                          <table className="min-w-full table-auto border-collapse border border-gray-300 rounded-lg text-sm">
                            <thead>
                              <tr className="bg-teal-300 text-gray-800">
                                <th className="border border-gray-300 px-4 py-2 text-left w-3/4">
                                  User Name
                                </th>
                                <th className="border border-gray-300 px-4 py-2 text-left w-1/4">
                                  Total Hours
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(project.users).map((userId) => {
                                const user = project.users[userId];
                                const totalUserHours = Object.values(
                                  user.hours
                                ).reduce((sum, task) => sum + task.hour, 0);

                                return (
                                  <React.Fragment key={userId}>
                                    {/* User Row */}
                                    <tr
                                      className={`cursor-pointer bg-gray-50 hover:bg-teal-100 transition duration-300 ${
                                        expandedUser === userId
                                          ? 'bg-teal-200'
                                          : ''
                                      }`}
                                      onClick={() => toggleExpandUser(userId)}
                                    >
                                      <td className="border border-gray-300 px-4 py-2">
                                        {user.name}
                                      </td>
                                      <td className="border border-gray-300 px-4 py-2">
                                        {totalUserHours.toFixed(2)}
                                      </td>
                                    </tr>

                                    {/* Expandable Row for Tasks */}
                                    {expandedUser === userId && (
                                      <tr>
                                        <td colSpan="2">
                                          <motion.div
                                            initial={{
                                              opacity: 0,
                                              height: 0,
                                            }}
                                            animate={{
                                              opacity: 1,
                                              height: 'auto',
                                            }}
                                            transition={{ duration: 0.2 }}
                                            className="p-2 bg-teal-50 rounded-lg"
                                          >
                                            <table className="min-w-full table-auto border-collapse border border-gray-300 rounded-lg text-sm">
                                              <thead>
                                                <tr className="bg-blue-200 text-gray-800 font-medium">
                                                  <th className="border border-gray-300 px-4 py-2 text-left w-3/4">
                                                    Task
                                                  </th>
                                                  <th className="border border-gray-300 px-4 py-2 text-left w-1/4">
                                                    Hours
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {Object.keys(user.hours).map(
                                                  (taskId) => {
                                                    const task =
                                                      user.hours[taskId];
                                                    return (
                                                      <tr key={taskId}>
                                                        <td className="border border-gray-300 px-4 py-2 ">
                                                          {task.name}
                                                        </td>
                                                        <td className="border border-gray-300 px-4 py-2">
                                                          {task.hour}
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>
                                          </motion.div>
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </motion.div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

import React from "react";

function TaskSummaryView({ projectData: project }) {
  console.log("Project Data", project);
  return (
    <div>
      <h2 className="text-3xl font-semibold my-5 text-center">
        Task Distribution
      </h2>
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left">Task</th>
            <th className="border border-gray-300 px-4 py-2 text-left font-bold">
              Hours
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Iterate over the tasks of each project */}
          <tr className="bg-gray-200 font-semibold">
            {/* Add a row for total hours at the bottom of each project */}
            <td className="border border-gray-300 px-4 py-2">Total Hours</td>
            <td className="border border-gray-300 px-4 py-2">
              {project?.totalProjectHours?.toFixed(1)}{" "}
              {/* Display total project hours */}
            </td>
          </tr>
          {project?.map((task, index) => (
            <tr key={index} className="bg-white odd:bg-gray-50">
              <td className="border border-gray-300 px-4 py-2">
                {task?.shortName}
              </td>
              <td className="border border-gray-300 px-4 py-2 font-bold">
                {task?.value?.toFixed(1)} {/* Display task's total hours */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TaskSummaryView;

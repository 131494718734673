import React, { useEffect, useState } from "react";
import axios from "axios";

const FSUtilizationTable = ({ startDate, endDate }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!startDate || !endDate) return;

      setLoading(true);
      try {
        const response = await axios.get(
          `https://timesheet-be.fleetstudio.com/api/user/reports/getFSLabsTimesheetInRange/${startDate}/${endDate}`
        );
        setData(response.data.data);
        setError(null);
      } catch (err) {
        setError(err.message || "Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  if (loading) return <p>Loading FS Labs Utilization...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h2>FS Labs Utilization</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Projects</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((user, index) => (
              <tr key={index}>
                <td>{user.user}</td>
                <td>
                  <ul>
                    {user.projects.map((project, i) => (
                      <li key={i}>
                        {project.project}: {project.totalHours.toFixed(1)} hours
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No data available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(FSUtilizationTable);

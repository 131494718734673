export const getShortNameForProjects = (longName) => {
  switch (longName) {
    case '00-Holiday':
      return 'HOL';
    case '01-Leave':
      return 'LV';
    case '00-Holiday (All declared holidays (except ones fall on week end) to be logged with standard 8 Hrs)':
      return 'HOL';
    case '01-Leave (Personal, Sick or any leave taken)':
      return 'LV';
    case '02-GPTW / TownHall':
      return 'GPTWTH';
    case '03-Interview':
      return 'INTVW';
    case '03-Interview (Panelists and HM)':
      return 'INTVW';
    case 'ABA-IndieCommerce':
      return 'ABA-IC';
    case 'Academy':
      return 'ACD';
    case 'andKind':
      return 'ANDK';
    case 'AT&T-OFC':
      return 'ATT-OFC';
    case 'AUDD':
      return 'AUDD';
    case 'Broadland Solutions':
      return 'BRDLND';
    case 'CaptuRisk':
      return 'CAPRISK';
    case 'CFO':
      return 'CFO';
    case 'Corsaire':
      return 'CRSR';
    case 'DAC':
      return 'DAC';
    case 'Fleet Studio (Operations, Operations related Research, Leadership meetings)':
      return 'FS-OPS';
    case 'Fleet Studio - Website':
      return 'FS-WEB';
    case 'Fleet Studio Accounting (ONLY GST, PF, Payroll)':
      return 'FS-ACCT';
    case 'FleetStudio Administration (Vendor Management/ Office Maintenance/ Hardware Management)':
      return 'FS-ADMIN';
    case 'FleetStudio Administration ( Vendor Management/ Office Maintenance/ Hardware Managment...(':
      return 'FS-ADMIN';
    case 'FS Apostrophe':
      return 'FS-APO';
    case 'FS Auth (FS Garage)':
      return 'FS-AUTH';
    case 'FS Campus Tool':
      return 'FS-CT';
    case 'FS CMS':
      return 'FS-CMS';
    case 'FS Connect':
      return 'FS-CONN';
    case 'FS Labs':
      return 'FS-LABS';
    case 'FS Xper':
      return 'FS-XPER';
    case 'Grindstone Advisors':
      return 'GSA';
    case 'HR (GPTW, Interviews, Town Hall, HR, Recruitment)':
      return 'HR';
    case 'Intern Training':
      return 'INT-TRNG';
    case 'Learning':
      return 'LRN';
    case 'LIT Fitness':
      return 'LIT-FIT';
    case 'Marketing':
      return 'MKT';
    case 'Medo Health':
      return 'MEDO';
    case 'Office of the CEO':
      return 'CEO';
    case 'Party Time':
      return 'PTY-TM';
    case 'PPL Events':
      return 'PPL-EVT';
    case 'Project X':
      return 'PRJX';
    case 'QAAS':
      return 'QAAS';
    case 'Sitetracker':
      return 'SITRCKR';
    case 'Thirstie':
      return 'THIRSTIE';
    case 'TIBA':
      return 'TIBA';
    case 'Training':
      return 'TRNG';
    case 'uCom':
      return 'UCOM';
    case 'Wally':
      return 'WLY';
    case 'WallyAX - Website':
      return 'WLY-WBST';
    case 'Wally PSO':
      return 'WLY-PSO';
    case 'WhatsUp':
      return 'WUP';
    case 'Betterup':
      return 'BUP';
    case 'BetterUp':
      return 'BUP';
    case 'Sethi Clarity Advisers':
      return 'SCA';
    case 'Conference Website - SF Community':
      return 'CWSFC';
    case 'FS-Labs-Worshops':
      return 'FS-LB-WRKSHP';
    case 'FS-Labs-Timesheet':
      return 'FS-LB-TMSHT';
    case 'FS-Labs-Reports':
      return 'FS-LB-RPTS';
    default:
      return 'Unknown Project';
  }
};

export const getShortNameForTask = (longName) => {
  switch (longName) {
    case '00-Holiday/Leave (Use this if you chose Holiday or Leave as project)':
      return 'HL-LV';
    case 'Attending/Conducting Training':
      return 'TRNG';
    case 'Content Marketing':
      return 'CNT-MKT';
    case 'Data Warehouse':
      return 'DW';
    case 'Development':
      return 'DEV';
    case 'Documentation':
      return 'DOC';
    case 'Email Marketing':
      return 'EML-MKT';
    case 'GPTW':
      return 'GPTW';
    case 'Infrastructure':
      return 'INFRA';
    case 'Interview':
      return 'INTVW';
    case 'Knowledge Transfer':
      return 'KT';
    case 'Lead Generation':
      return 'LD-GEN';
    case 'Management & Coordination':
      return 'MGMT-COORD';
    case 'Meeting (General - any meeting NOT related to the current project(s))':
      return 'MTG-GEN';
    case 'Meeting (Project - any meeting client or internal related to the current project(s))':
      return 'MTG-PROJ';
    case 'Non-billable (Idle time if there are no assigned project task also not worked on any other relevant tasks like learning etc.,)':
      return 'NON-BILL';
    case 'Product Management':
      return 'PRD-MGMT';
    case 'Product Marketing':
      return 'PRD-MKT';
    case 'Product/Project Learning (learning a new feature or learning the product/project when newly onboarded)':
      return 'PRD-LRN';
    case 'Project Management':
      return 'PRJ-MGMT';
    case 'QA - Testing':
      return 'QA';
    case 'R & D':
      return 'RD';
    case 'Recruiting / Followup':
      return 'REC-FU';
    case 'Sales':
      return 'SALES';
    case 'Social Media':
      return 'SOC-MEDIA';
    case 'Support':
      return 'SUP';
    case 'Tech Help':
      return 'TECH-HELP';
    default:
      return 'Unknown Task';
  }
};
export const getMediumNameForProjects = (longName) => {
  switch (longName) {
    case '00-Holiday (All declared holidays (except ones fall on week end) to be logged with standard 8 Hrs)':
      return 'Holiday';
    case '01-Leave (Personal, Sick or any leave taken)':
      return 'Leave';
    case '03-Interview (Panelists and HM)':
      return 'Interview';
    case 'Fleet Studio (Operations, Operations related Research, Leadership meetings)':
      return 'Fleet Studio Operations';
    case 'Fleet Studio - Website':
      return 'Fleet Studio Website';
    case 'Fleet Studio Accounting (ONLY GST, PF, Payroll)':
      return 'Fleet Studio Accounting';
    case 'FleetStudio Administration (Vendor Management/ Office Maintenance/ Hardware Management)':
    case 'FleetStudio Administration ( Vendor Management/ Office Maintenance/ Hardware Managment...(':
      return 'FleetStudio Administration';
    case 'HR (GPTW, Interviews, Town Hall, HR, Recruitment)':
      return 'HR';
    case '00-Holiday':
      return 'Holiday';
    case '01-Leave':
      return 'Leave';
    case '02-GPTW / TownHall':
      return 'GPTW/TownHall';
    case '03-Interview':
      return 'Interview';
    case 'ABA-IndieCommerce':
      return 'ABA IndieCommerce';
    case 'Academy':
      return 'Academy';
    case 'andKind':
      return 'andKind';
    case 'AT&T-OFC':
      return 'AT&T OFC';
    case 'AUDD':
      return 'AUDD';
    case 'Broadland Solutions':
      return 'Broadland Solutions';
    case 'CaptuRisk':
      return 'CaptuRisk';
    case 'CFO':
      return 'CFO';
    case 'Corsaire':
      return 'Corsaire';
    case 'DAC':
      return 'DAC';
    case 'FS Apostrophe':
      return 'FS Apostrophe';
    case 'FS Auth (FS Garage)':
      return 'FS Auth';
    case 'FS Campus Tool':
      return 'FS Campus Tool';
    case 'FS CMS':
      return 'FS CMS';
    case 'FS Connect':
      return 'FS Connect';
    case 'FS Labs':
      return 'FS Labs';
    case 'FS Xper':
      return 'FS Xper';
    case 'Grindstone Advisors':
      return 'Grindstone Advisors';
    case 'Intern Training':
      return 'Intern Training';
    case 'Learning':
      return 'Learning';
    case 'LIT Fitness':
      return 'LIT Fitness';
    case 'Marketing':
      return 'Marketing';
    case 'Medo Health':
      return 'Medo Health';
    case 'Office of the CEO':
      return 'Office of the CEO';
    case 'Party Time':
      return 'Party Time';
    case 'PPL Events':
      return 'PPL Events';
    case 'Project X':
      return 'Project X';
    case 'QAAS':
      return 'QAAS';
    case 'Sitetracker':
      return 'Sitetracker';
    case 'Thirstie':
      return 'Thirstie';
    case 'TIBA':
      return 'TIBA';
    case 'Training':
      return 'Training';
    case 'uCom':
      return 'uCom';
    case 'Wally':
      return 'Wally';
    case 'WallyAX - Website':
      return 'WallyAX Website';
    case 'Wally PSO':
      return 'Wally PSO';
    case 'WhatsUp':
      return 'WhatsUp';
    case 'Betterup':
      return 'BUP';
    case 'Sethi Clarity Advisers':
      return 'SCA';
    case 'Conference Website - SF Community':
      return 'CWSFC';
    case 'BetterUp':
      return 'BUP';
    case 'Sethi Clarity Advisers':
      return 'Sethi Clarity Advisers';
    case 'Conference Website - SF Community':
      return 'Conference Website - SF Community';
    case 'FS-Labs-Worshops':
      return 'FS-Labs-Workshops';
    case 'FS-Labs-Timesheet':
      return 'FS-Labs-Timesheet';
    case 'FS-Labs-Reports':
      return 'FS-Labs-Reports';
    default:
      return longName; // Return original if no changes are needed
  }
};
export const getMediumNameForTask = (longName) => {
  switch (longName) {
    case '00-Holiday/Leave (Use this if you chose Holiday or Leave as project)':
      return 'Holiday/Leave';
    case 'Attending/Conducting Training':
      return 'Training';
    case 'Content Marketing':
      return 'Content Marketing';
    case 'Data Warehouse':
      return 'Data Warehouse';
    case 'Development':
      return 'Development';
    case 'Documentation':
      return 'Documentation';
    case 'Email Marketing':
      return 'Email Marketing';
    case 'GPTW':
      return 'GPTW';
    case 'Infrastructure':
      return 'Infrastructure';
    case 'Interview':
      return 'Interview';
    case 'Knowledge Transfer':
      return 'Knowledge Transfer';
    case 'Lead Generation':
      return 'Lead Generation';
    case 'Management & Coordination':
      return 'Management & Coordination';
    case 'Meeting (General - any meeting NOT related to the current project(s))':
      return 'General Meeting';
    case 'Meeting (Project - any meeting client or internal related to the current project(s))':
      return 'Project Meeting';
    case 'Non-billable (Idle time if there are no assigned project task also not worked on any other relevant tasks like learning etc.,)':
      return 'Non-billable';
    case 'Product Management':
      return 'Product Management';
    case 'Product Marketing':
      return 'Product Marketing';
    case 'Product/Project Learning (learning a new feature or learning the product/project when newly onboarded)':
      return 'Product/Project Learning';
    case 'Project Management':
      return 'Project Management';
    case 'QA - Testing':
      return 'QA Testing';
    case 'R & D':
      return 'R&D';
    case 'Recruiting / Followup':
      return 'Recruiting';
    case 'Sales':
      return 'Sales';
    case 'Social Media':
      return 'Social Media';
    case 'Support':
      return 'Support';
    case 'Tech Help':
      return 'Tech Help';
    default:
      return longName; // Return original if no changes are needed
  }
};

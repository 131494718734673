import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Navbar from './components/Navbar';
import TimesheetDashboard from './components/TimesheetDashboard';
import OverallAnalysis from './components/OverallAnalysis';
import Dashboard from './components/Dashboard';
import TimeOffComponent from './components/TimeOffComponent';
import ProjectAnalysis from './components/ProjectAnalysis';
import LoginComponent from './components/Login';
import ProtectedRoute from './auth/ProtectedRoute';
import AttendanceByDate from './components/atOffice';
import AttendanceByUser from './components/attendance';
import Utilization from './components/Utilization';
import DetailedUtilization from './components/detailedUtilization';
import DetailedUtilizationV2 from './components/DetailedUtilisationV2/DetailedUtilisationV2';
import UserAnalysisBeta from './components/UserAnalysisBeta/UserAnalysisBeta';

const App = () => {
  return (
    <Router>
      <Navbar /> {/* Make sure Navbar renders across all pages */}
      <Routes>
        {/* Public route: Login */}
        <Route path="/login" element={<LoginComponent />} />

        {/* Protected routes: Dashboard and its sub-routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              {' '}
              {/* Wrap ProtectedRoute around Dashboard */}
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route
            path="overall-analysis"
            element={
              <ProtectedRoute>
                <OverallAnalysis />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-analysis"
            element={
              <ProtectedRoute>
                {/* <TimesheetDashboard /> */}
                <UserAnalysisBeta />
              </ProtectedRoute>
            }
          />
          <Route
            path="project-analysis"
            element={
              <ProtectedRoute>
                <ProjectAnalysis />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeOff"
            element={
              <ProtectedRoute>
                <TimeOffComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="attendance"
            element={
              <ProtectedRoute>
                <AttendanceByDate />
              </ProtectedRoute>
            }
          />
          <Route
            path="Utilization"
            element={
              <ProtectedRoute>
                <Utilization />
              </ProtectedRoute>
            }
          />
          <Route
            path="detailed-utilization"
            element={
              <ProtectedRoute>
                <DetailedUtilization />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-attendance"
            element={
              <ProtectedRoute>
                <AttendanceByUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-attendance"
            element={
              <ProtectedRoute>
                <AttendanceByUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-analysis-beta"
            element={
              <ProtectedRoute>
                <UserAnalysisBeta />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="detailed-utilizationv2"
          element={
            <ProtectedRoute>
              <DetailedUtilizationV2 />
            </ProtectedRoute>
          }
        />

        {/* Redirect root path to user-analysis */}
        <Route
          path="/"
          element={<Navigate to="/dashboard/user-analysis" replace />}
        />
      </Routes>
    </Router>
  );
};

export default App;

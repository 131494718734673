import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  getMediumNameForProjects,
  getShortNameForProjects,
} from "../../utensils/simplifier";

// Colors for pie chart segments
const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff8042", "#d0ed57"];

export const ProjectPieChart = ({ data }) => {
  return (
    <ResponsiveContainer
      width="100%"
      height={500}
      className="flex-1 justify-center items-center"
    >
      <h1 className="self-center text-center text-3xl font-semibold">
        Project Distribution
      </h1>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={200}
          innerRadius={100}
          paddingAngle={5}
          fill="#82ca9d"
          label={(entry) =>
            `${getShortNameForProjects(entry.name)}: ${entry.value} hours`
          }
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip
          //   formatter={(value) => `${value} hours`}
          formatter={(value, name, entry) => {
            const mediumName = entry.payload.mediumName; // Show medium name in tooltip
            return [`${value} hours`, getMediumNameForProjects(name)];
          }}
        />
        <Legend
          verticalAlign="bottom"
          iconType="circle"
          formatter={(value) => (
            <span style={{ fontSize: "12px", color: "#333" }}>
              {getShortNameForProjects(value)}
            </span>
          )}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

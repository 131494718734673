export function TaskDistribution({ projectsData }) {
  return (
    <div className="p-4">
      <h2 className="text-3xl font-semibold my-5 text-center">
        Task Distribution
      </h2>

      {/* Iterate over each project and create a table for each */}
      {projectsData?.map((project) => (
        <div key={project.projectName} className="mb-6">
          <h3 className="text-lg font-semibold mb-4">{project.projectName}</h3>

          {/* Project-level summary showing total project hours */}
          {/* <div className="mb-2">
            <strong>Total Project Hours: </strong>
            {project?.totalProjectHours?.toFixed(1)} hours
          </div> */}

          {/* Table for task details */}
          <table className="min-w-full table-auto border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Task
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left font-bold">
                  Hours
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Iterate over the tasks of each project */}
              {project?.tasks?.map((task, index) => (
                <tr key={index} className="bg-white odd:bg-gray-50">
                  <td className="border border-gray-300 px-4 py-2">
                    {task?.task}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 font-bold">
                    {task?.totalHours?.toFixed(1)}{" "}
                    {/* Display task's total hours */}
                  </td>
                </tr>
              ))}
              {/* Add a row for total hours at the bottom of each project */}
              <tr className="bg-gray-200 font-semibold">
                <td className="border border-gray-300 px-4 py-2">
                  Total Hours
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {project?.totalProjectHours?.toFixed(1)}{" "}
                  {/* Display total project hours */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSearchParams } from "react-router-dom";

import { getShortNameForProjects } from "../../utensils/simplifier";
import { ProjectPieChart } from "./ProjectPieChart";
import { TaskDistribution } from "./TaskAnalysixPieChart";
import HeaderToolbar from "./DatePickerHeader";
import {
  differenceInCalendarDays,
  endOfDay,
  format,
  getISOWeek,
  startOfMonth,
  startOfWeek,
  subWeeks,
} from "date-fns";
import { getMonthDates, getWeekDates } from "../Utils";
import { BASE_URL } from "../../constants/constants";
import TaskSummaryView from "./TaskSummaryView";
import { ShowComments } from "../TimesheetDashboard";
import { FiLoader } from "react-icons/fi";
import { RiLoader4Fill } from "react-icons/ri";
function UserAnalysisBeta() {
  const [userProjectBreakDown, setUserProjectBreakDown] = useState([]);
  const [taskDistribution, setTaskDistribution] = useState([]);
  const [projectDistribution, setProjectDistribution] = useState([]);

  const [selectedOption, setSelectedOption] = useState("week");
  const [startDate, setStartDate] = useState(new Date());
  const [selectedUser, setSelectedUser] = useState(null);

  const [dateRange, setDateRange] = useState(getWeekDates(startDate));
  const [activeUsers, setActiveUsers] = useState([]);
  const timesheetData = {}; // Replace with your actual data

  const handleOptionChange = (e) => setSelectedOption(e.target.value);
  const handleDateChange = (date) => setStartDate(date);
  const handleUserChange = (e) => setSelectedUser(e.target.value);
  const adjustDates = (direction) => {
    console.log(`Adjusting dates: ${direction}`);
  };
  //   const handleExportCSV = () => {
  //     handleExportCSV();
  //   };

  const [currentView, setCurrentView] = useState("project");
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const startDateParams = searchParams.get("startDate");
  const endDateParams = searchParams.get("endDate");
  const userIdParams = searchParams.get("userId");

  console.log(startDateParams, endDateParams, userIdParams);
  const getAdjustedStartDate = (now) => {
    // Check if the current month is January and it's the first week
    const isJanuary = now.getMonth() === 0; // Check if the month is January
    const startOfCurrentWeek = startOfWeek(now, { weekStartsOn: 1 }); // Monday of the current week
    const daysFromStartOfMonth = differenceInCalendarDays(
      startOfCurrentWeek,
      startOfMonth(now)
    );

    // If in January and within the first week, set startDate to January 1st
    const startDate =
      isJanuary && daysFromStartOfMonth < 7
        ? startOfMonth(now) // First day of January
        : startOfCurrentWeek; // Otherwise, Monday of the current week

    // Format the date for better readability
    return format(startDate, "yyyy-MM-dd");
  };

  //   useEffect(() => {
  //     // if (startDateParams) {
  //     //   const lastWeekMonday = startOfWeek(subWeeks(new Date(), 1), {
  //     //     weekStartsOn: 1,
  //     //   });
  //     // }
  //     if (userIdParams) {
  //       setSelectedUser(userIdParams);
  //       //   const lastWeekMonday = startOfWeek(
  //       //     subWeeks(new Date(startDateParams), 1),
  //       //     {
  //       //       weekStartsOn: 1,
  //       //     }
  //       //   );
  //       const monday = getAdjustedStartDate(new Date(startDateParams));

  //       //         startOfWeek(new Date(startDateParams), {
  //       //     weekStartsOn: 1,
  //       //   });

  //       console.log("Date Here", monday);
  //       setStartDate(monday);
  //     }
  //   }, [startDateParams]);

  useEffect(() => {
    getAllUsers();
    if (userIdParams && startDateParams) {
      setSelectedUser(userIdParams);
      //   const monday = getAdjustedStartDate(new Date(startDateParams));
      setStartDate(new Date(startDateParams));
    } else {
      const lastWeekMonday = startOfWeek(subWeeks(new Date(), 1), {
        weekStartsOn: 1,
      });
      setStartDate(lastWeekMonday);
    }
  }, []);

  useEffect(() => {
    if (selectedOption === "week") {
      setDateRange(getWeekDates(startDate));
    } else {
      setDateRange(getMonthDates(startDate));
    }
  }, [startDate, selectedOption]);

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/user/getUsers`);
      const users = response?.data?.map((user) => ({
        _id: user._id,
        fname: user.fname,
        lname: user.lname,
        email: user.email,
        uid: user.wup_username,
      }));
      setActiveUsers(users);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const UserData = async () => {
    if (!selectedUser) {
      return;
    }
    try {
      setIsLoading(true);

      const weekNumber = getISOWeek(new Date(startDate));
      let response;
      if (
        weekNumber === 1 &&
        new Date(startDateParams).getFullYear() === 2025
      ) {
        response = await axios.get(
          `${BASE_URL}/api/user/getTimesheetByDateRangeAndUid/2025-01-01/2025-01-03/${selectedUser}`
        );
        console.log("Week Number", weekNumber);
      } else {
        response = await axios.get(
          `${BASE_URL}/api/user/getTimesheetByDateRangeAndUid/${dateRange.startDate}/${dateRange.endDate}/${selectedUser}`
        );
      }
      setUserProjectBreakDown(response.data);
      const taskDistribution = transformToTaskDistribution(response.data);
      const projectDistribution = transformToProjectPieData(response.data);
      console.log("Project Distribution", taskDistribution);
      setTaskDistribution(taskDistribution);
      setProjectDistribution(projectDistribution);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    UserData();
  }, [dateRange, selectedUser]);

  const transformToTaskDistribution = (projectsData) => {
    // Flatten and sum up hours for the same task across all projects
    const taskDistribution = projectsData.flatMap((project) => {
      return project?.tasks?.map((task) => ({
        shortName: task?.task, // The task name
        value: task?.totalHours, // The total hours for the task
        mediumName: `${getShortNameForProjects(project.projectName)} - ${
          task.task
        }`, // Combine project and task name for the tooltip
      }));
    });

    // Sum up hours for the same task
    const summedTaskDistribution = taskDistribution?.reduce((acc, task) => {
      const existingTask = acc.find((t) => t.shortName === task.shortName);

      if (existingTask) {
        existingTask.value += task.value; // Add the hours to the existing task
      } else {
        acc.push({ ...task }); // Add a new task entry if not found
      }

      return acc;
    }, []);

    return summedTaskDistribution;
  };

  const transformToProjectDistribution = (projectsData) => {
    return projectsData?.map((project) => ({
      projectName: project.projectName,
      totalHours: project.totalProjectHours,
      tasks: project.tasks.map((task) => ({
        taskName: task.task,
        totalHours: task.totalHours,
      })),
    }));
  };

  const handleExportCSV = () => {
    // Helper to convert array of objects into CSV format
    const convertToCSV = (rows) => {
      const csvContent = rows
        .map((row) =>
          Object.values(row)
            .map((value) => `"${value}"`)
            .join(",")
        )
        .join("\n");
      return csvContent;
    };

    const formatDate = (date) => {
      const d = new Date(date);
      const month = d.toLocaleString("default", { month: "short" }); // "Jun" for June
      const day = String(d.getDate()).padStart(2, "0"); // "01" for day 1
      const year = d.getFullYear(); // 2024
      return `${month}_${day}_${year}`;
    };

    // if (!timesheetData || !Object.keys(timesheetData).length) {
    //   console.error("No data available for export.");
    //   return;
    // }

    const projectRows = projectDistribution.map((pro) => {
      return {
        ProjectName: pro.name,
        ProjectHours: pro.value,
      };
    });

    const taskRows = taskDistribution?.map((task) => {
      return {
        TaskName: task.mediumName,
        TaskHours: task.value,
      };
    });
    const filteredUser = activeUsers.find((user) => selectedUser === user._id);
    let userName = `${filteredUser.fname} ${filteredUser.lname}`;
    console.log("User Name", userName);

    // Convert both tables to CSV format
    const projectCSV = convertToCSV([
      ["Project Name", "Hours"],
      ...projectRows.map((row) => [row.ProjectName, row.ProjectHours]),
    ]);
    const taskCSV = convertToCSV([
      ["Task Name", "Hours"],
      ...taskRows.map((row) => [row.TaskName, row.TaskHours]),
    ]);

    // Combine CSV tables with a header for the user's name
    const finalCSV = `${userName}'s Timesheet:\n\nProject Table:\n${projectCSV}\n\nTask Table:\n${taskCSV}`;

    // Format the dates for the filename
    const formattedStartDate = formatDate(dateRange.startDate);
    const formattedEndDate = formatDate(dateRange.endDate);

    // Create a blob and initiate a download
    const blob = new Blob([finalCSV], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute(
      "download",
      `${userName}_Timesheet_${formattedStartDate}_to_${formattedEndDate}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const transformToProjectPieData = (projectsData) => {
    return projectsData?.map((project) => ({
      shortName: getShortNameForProjects(project?.projectName),
      name: project?.projectName,
      value: project?.totalProjectHours,
    }));
  };

  return (
    <div>
      <HeaderToolbar
        selectedOption={selectedOption}
        handleOptionChange={handleOptionChange}
        startDate={startDate}
        handleDateChange={handleDateChange}
        selectedUser={selectedUser}
        handleUserChange={handleUserChange}
        activeUsers={activeUsers}
        adjustDates={adjustDates}
        handleExportCSV={handleExportCSV}
        timesheetData={timesheetData}
      />
      {!isLoading && selectedUser && dateRange ? (
        <div className="">
          <div className="flex gap-4 my-20">
            <RenderTaskDistributionView taskDistribution={taskDistribution} />
            <ProjectPieChart data={projectDistribution} />
          </div>
          <div className=" flex gap-5 ms-10">
            <button
              onClick={() => setCurrentView("project")}
              className={`border ${
                currentView === "project" ? "bg-orange-500" : "bg-blue-300"
              } rounded-xl text-white px-4 py-2`}
            >
              Project View
            </button>
            <button
              className={`border ${
                currentView === "task" ? "bg-orange-500" : "bg-blue-300"
              } rounded-xl text-white px-4 py-2`}
              onClick={() => setCurrentView("task")}
            >
              Task View
            </button>
          </div>
          <div className="px-10">
            {currentView === "project" ? (
              <TaskDistribution projectsData={userProjectBreakDown} />
            ) : (
              <TaskSummaryView projectData={taskDistribution} />
            )}
          </div>
          {selectedUser && (
            <ShowComments
              uid={
                activeUsers?.find((user) => user?._id === selectedUser)
                  ?.wup_username
              }
              startDate={dateRange?.startDate}
              endDate={dateRange?.endDate}
            />
          )}
        </div>
      ) : !isLoading ? (
        <div className="text-center font-bold text-3xl py-3   my-5">
          Please Select User and Date
        </div>
      ) : (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-800/80 flex justify-center z-50 items-center">
          <RiLoader4Fill className="text-7xl text-white animate-spin" />
        </div>
      )}
    </div>
  );
}

export default UserAnalysisBeta;

const COLORS = ["#8884d8", "#82ca9d", "#ffbb28", "#ff8042"]; // Example color palette

const RenderTaskDistributionView = ({ taskDistribution }) => {
  return (
    <ResponsiveContainer
      width="100%"
      height={500}
      className="flex-1  justify-center"
    >
      <h1 className=" text-center text-3xl font-semibold">Task Distribution</h1>
      <PieChart>
        <Pie
          data={taskDistribution}
          dataKey="value"
          nameKey="shortName" // Display short name in the legend
          cx="50%"
          cy="50%"
          outerRadius={200}
          innerRadius={100}
          paddingAngle={5}
          fill="#8884d8"
          label={false}
        >
          {taskDistribution?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip
          formatter={(value, name, entry) => {
            const mediumName = entry.payload.mediumName; // Show medium name in tooltip
            return [`${value} hours`, mediumName];
          }}
        />
        <Legend
          verticalAlign="bottom"
          wrapperStyle={{
            display: "block",
            width: "100%",
            columnCount: 1,
            overflow: "auto",
            maxHeight: "100px",
          }}
          formatter={(value) => (
            <span style={{ fontSize: "12px", color: "#333" }}>{value}</span>
          )}
          iconType="circle"
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

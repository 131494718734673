import twentyFour from './years/2024.json';
import twentyFive from './years/2025.json';
import twentythree from './years/2023.json';
import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
export function getCurrentWeekNumber() {
  const currentDate = new Date();

  // Set the start of the year (January 1st)
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);

  // Calculate the difference in milliseconds
  const diffInMilliseconds = currentDate - startOfYear;

  // Calculate the number of days from the start of the year
  const daysSinceStartOfYear = Math.floor(
    diffInMilliseconds / (24 * 60 * 60 * 1000)
  );

  // Get the week number
  // Day of week offset: Adjust to make Monday the first day of the week
  const dayOfWeekOffset =
    startOfYear.getDay() === 0 ? 6 : startOfYear.getDay() - 1;
  const weekNumber = Math.ceil(
    (daysSinceStartOfYear + dayOfWeekOffset + 1) / 7
  );

  return weekNumber;
}

export function getMonthRanges(year) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthRanges = [];

  for (let month = 0; month < 12; month++) {
    const start = new Date(year, month, 1); // First day of the month
    const end = new Date(year, month + 1, 0); // Last day of the month
    monthRanges.push({
      month: month + 1, // Month number (1-12)
      name: monthNames[month], // Month name
      start: `${start.getFullYear()}-${String(start.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(start.getDate()).padStart(2, '0')}`,
      end: `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(end.getDate()).padStart(2, '0')}`,
    });
  }

  return monthRanges;
}

export function getWeekRanges(year) {
  // const startOfYear = new Date(Date.UTC(year, 0, 1)); // January 1st (UTC)
  // const endOfYear = new Date(Date.UTC(year, 11, 31)); // December 31st (UTC)
  const weeks = [];

  // let currentDate = startOfYear;

  // // Adjust the starting date to the nearest Monday (ISO week starts on Monday)
  // while (currentDate.getUTCDay() !== 1) {
  //   currentDate.setUTCDate(currentDate.getUTCDate() - 1);
  // }

  // let weekNumber = 1;

  //   weekNumber++;
  //   currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  if (year === 2024) {
    const constructedYear = twentyFour.map((year) => {
      return {
        week: year.week,
        start: year.start,
        end: year.end,
        days: year.days, // Assuming `days` is defined elsewhere or part of `year`
      };
    });
    return constructedYear;
  }
  if (year === 2025) {
    const constructedYear = twentyFive.map((year) => {
      return {
        week: year.week,
        start: year.start,
        end: year.end,
        days: year.days, // Assuming `days` is defined elsewhere or part of `year`
      };
    });
    return constructedYear;
  }
  if (year === 2023) {
    const constructedYear = twentythree.map((year) => {
      return {
        week: year.week,
        start: year.start,
        end: year.end,
        days: year.days, // Assuming `days` is defined elsewhere or part of `year`
      };
    });
    return constructedYear;
  }

  return weeks;
}

export const getWeekDates = (currentDate = new Date()) => {
  // Get the start and end of the week
  const startDate = startOfWeek(currentDate, { weekStartsOn: 0 }); // Sunday as the first day
  const endDate = endOfWeek(currentDate, { weekStartsOn: 0 });

  // Get all the dates within the week
  const weekDates = eachDayOfInterval({ start: startDate, end: endDate });

  // Format the dates for better readability
  const formattedDates = weekDates.map((date) => format(date, 'yyyy-MM-dd'));

  return {
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
    weekDates: formattedDates,
  };
};
export const getMonthDates = (currentDate = new Date()) => {
  // Get the start and end of the month
  const startDate = startOfMonth(currentDate); // Start of the month
  const endDate = endOfMonth(currentDate); // End of the month

  // Get all the dates within the month
  const monthDates = eachDayOfInterval({ start: startDate, end: endDate });

  // Format the dates for better readability
  const formattedDates = monthDates.map((date) => format(date, 'yyyy-MM-dd'));

  return {
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
    monthDates: formattedDates,
  };
};

import React, { useState, useEffect } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import ProgressLoader from '../utensils/ProgressLoader';
import axios from 'axios';
import { FaInfoCircle, FaFlag } from 'react-icons/fa';
import {
  addWeeks,
  subWeeks,
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  format,
  differenceInCalendarWeeks,
  startOfYear,
  endOfYear,
} from 'date-fns';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { RiLoader4Fill } from 'react-icons/ri';

// const StyledContainer = styled(Container)({
//   backgroundColor: "#FFFFFF",
//   padding: "30px",
//   borderRadius: "16px",
//   boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
//   maxWidth: "100%",
// });
export const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto', // Use full viewport height
  backgroundColor: '#FFFFFF',
  padding: '30px',
  borderRadius: '16px',
  boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1)',
  maxWidth: '100%',
});

export const TableContainer = styled('div')({
  flex: 1,
  overflowY: 'auto',
  // Allows the table to take up the remaining height
  // Enables vertical scrolling for the table
  marginTop: '60px', // Adds spacing below the chartf
  border: '1px solid #ddd',
  borderRadius: '12px',
});

export const Title = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
  paddingBottom: '8px',
  borderBottom: '2px solid #0d47a1',
});

const StyledTableCell = styled(TableCell)({
  position: 'sticky',
  top: 0,
  backgroundColor: '#f1f1f1', // Background color for sticky header
  fontWeight: 'bold',
  zIndex: 2, // Ensures it stays above other row
  textAlign: 'center',
});

// Function to calculate background color based on utilization
const calculateBackgroundColor = (diff) => {
  if (diff > 0) {
    return `rgba(144, 238, 144, ${Math.min(0.2 + diff / 100, 0.8)})`; // Subtle green
  } else if (diff < 0) {
    return `rgba(255, 99, 71, ${Math.min(0.2 + Math.abs(diff) / 100, 0.8)})`; // Subtle red
  }
  return 'transparent'; // No color for zero utilization
};

const Utilization = () => {
  // const [selectedWeek, setSelectedWeek] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('top5Over');
  const [sortBy, setSortBy] = useState('diff');
  const [sortDirection, setSortDirection] = useState('asc'); // "asc" or "desc"
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [projectDetails, setProjectDetails] = useState([]); // State for project details
  const [fetching, setFetching] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(new Date());
  const currentYear = new Date().getFullYear();

  const [yearRange, setYearRange] = useState(currentYear); // Initialize with current year
  const [weekRange, setWeekRange] = useState(() => ({
    start: startOfWeek(new Date(), { weekStartsOn: 1 }), // Current week's start
    end: endOfWeek(new Date(), { weekStartsOn: 1 }), // Current week's end
  }));

  // const handleDateChange = (newValue) => {
  //   const start = startOfWeek(new Date(newValue), { weekStartsOn: 1 });
  //   const end = endOfWeek(new Date(newValue), { weekStartsOn: 1 });
  //   setSelectedWeek(newValue);
  //   setWeekRange({ start, end });
  // };
  useEffect(() => {
    const firstDayOfYear = startOfYear(new Date(yearRange, 0, 1));
    const lastDayOfYear = endOfYear(new Date(yearRange, 11, 31));

    setWeekRange({
      start: startOfWeek(firstDayOfYear, { weekStartsOn: 1 }),
      end: endOfWeek(lastDayOfYear, { weekStartsOn: 1 }),
    });
    const weeks = generateWeeksForYear(yearRange);
    setWeekOptions(weeks);
  }, [yearRange]);

  const handleRowClick = async (row) => {
    setSelectedRow(row);
    setFetching(true);
    setDialogOpen(true);

    try {
      const userId = row.userId;
      // Function to format date in 'YYYY-MM-DD' without time zone shifts
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      // Example usage
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const apiUrl = `https://timesheet-be.fleetstudio.com/api/timesheet/getTimeSheets?userId=${userId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      const response = await axios.get(apiUrl);

      // Transform data to get project names and hours
      const projectData = response.data.reduce((acc, entry) => {
        const { projectName } = entry.field_proj;
        const hours = parseFloat(entry.field_time_spent || 0);

        if (projectName) {
          if (acc[projectName]) {
            acc[projectName] += hours; // Accumulate hours
          } else {
            acc[projectName] = hours; // Initialize hours
          }
        }
        return acc;
      }, {});

      setProjectDetails(Object.entries(projectData)); // Convert object to array for rendering
      const commentsApiUrl = `https://timesheet-be.fleetstudio.com/api/user/reports/getComments/${formattedStartDate}/${formattedEndDate}/${userId}`;
      const commentsResponse = await axios.get(commentsApiUrl);

      const flaggedComments =
        commentsResponse.data?.data?.[0]?.flagComment?.map((c) => c.query) ||
        [];
      setSelectedRow((prevRow) => ({
        ...prevRow,
        flaggedComments,
      }));
      setFetching(false);
    } catch (error) {
      console.error('Error fetching project details:', error);
    }
  };
  const navigate = useNavigate();

  const handleDetailedPage = () => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    navigate('/dashboard/detailed-utilization', {
      state: {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
    });
  };

  // Function to close dialog box
  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };
  const handleMenuChange = (event) => {
    const newOption = event.target.value;
    setSelectedOption(newOption);

    // If a single user is selected, reset to all users
    if (selectedName) {
      setSelectedName(''); // Reset selected user
      setFilteredData(data); // Reset filtered data to include all users
    }
  };
  // Fetch projects and users
  useEffect(() => {
    const fetchProjectsAndUsers = async () => {
      try {
        // Fetch projects
        const projectResponse = await axios.get(
          'https://timesheet-be.fleetstudio.com/api/project/projects'
        );
        const activeProjects = projectResponse.data.filter(
          (project) => project.status === 'active'
        );
        setProjects(activeProjects);

        // Fetch users
        const userResponse = await axios.get(
          'https://timesheet-be.fleetstudio.com/api/user/getUsers'
        );
        const allUsers = userResponse.data
          .map((user) => ({
            id: user._id,
            name: `${user.fname} ${user.lname}`.trim(),
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        setUsers(allUsers);
        setFilteredUsers(allUsers); // Initially show all users
      } catch (error) {
        console.error('Error fetching projects or users:', error);
      }
    };

    fetchProjectsAndUsers();
    setLoading(false);
  }, []);

  const handleSubmit = async () => {
    if (!selectedWeek) return;

    setLoading(true);
    try {
      // Ensure the selected date is cleaned up
      // const selectedDate = new Date(selectedWeek);
      // console.log('Selected Week', selectedWeek);
      // console.log('Selected Date', selectedDate);
      // selectedDate.setHours(0, 0, 0, 0); // Set time to midnight

      // // Adjust startDate to Monday of the selected week
      // const startDate = new Date(selectedDate);
      // const dayOfWeek = selectedDate.getDay();
      // const daysToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Ensure Monday
      // startDate.setDate(selectedDate.getDate() + daysToMonday);

      // // Calculate endDate as Sunday of the same week
      // const endDate = new Date(startDate);
      // endDate.setDate(startDate.getDate() + 6); // Sunday is 6 days after Monday
      // setStartDate(startDate);
      // setEndDate(endDate);

      // const formatDate = (date) => {
      //   const yyyy = date.getFullYear();
      //   const mm = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      //   const dd = String(date.getDate()).padStart(2, '0');
      //   return `${yyyy}-${mm}-${dd}`;
      // };
      function splitDateRange(dateRange) {
        if (!dateRange || typeof dateRange !== 'string') {
          throw new Error('Invalid date range format');
        }

        const [startDate, endDate] = dateRange.split(' - ');

        return {
          startDate: new Date(startDate.trim()).toISOString().split('T')[0], // Extract date part
          endDate: new Date(endDate.trim()).toISOString().split('T')[0], // Extract date part
        };
      }

      console.log('Selected Week', selectedWeek);
      const split = splitDateRange(selectedWeek);

      const formattedStartDate = split?.startDate;
      const formattedEndDate = split?.endDate;

      const apiUrl = `https://timesheet-be.fleetstudio.com/api/user/reports/filter/${formattedStartDate}/${formattedEndDate}`;
      const response = await axios.get(apiUrl);
      const rawData = response.data;

      const formatNumber = (value) =>
        Number.isInteger(value) ? value : parseFloat(value).toFixed(1);

      const flaggedCommentsData = await fetchFlaggedComments(
        formattedStartDate,
        formattedEndDate
      );
      const transformedData = rawData
        .filter((item) => item.isActive) // Include only active users
        .map((item, index) => {
          const diff =
            item.loggedHours -
            item.flaggedHours -
            (item.allocatedHours - item.holiday); // Calculate diff outside the object

          return {
            sNo: index + 1,
            name: item.name,
            userId: item.userId,
            type: item.employementStatus,
            allocated: formatNumber(item.allocatedHours), // Conditional formatting
            logged: formatNumber(item.loggedHours),
            holiday: formatNumber(item.holiday),
            leave: formatNumber(item.leave),
            learning: formatNumber(item.learning),
            flagged: formatNumber(item.flaggedHours),
            allocatedMinusHoliday: formatNumber(
              item.allocatedHours - item.holiday
            ), // Calculated column
            loggedMinusFlagged: formatNumber(
              item.loggedHours - item.flaggedHours - item.leave - item.learning
            ), // Calculated column
            diff: formatNumber(diff), // Store raw number
            diffDisplay: Number.isInteger(diff) ? diff : diff.toFixed(1), // For display
            comment: item.comment,
          };
        })
        .sort((a, b) => b.diff - a.diff);

      // Filter data directly for the selected user if applicable
      if (selectedName) {
        const filtered = transformedData.filter(
          (user) => user.userId === selectedName
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(transformedData);
      }

      // Fetch flagged comments

      // Merge flagged comments with utilization data
      const mergedData = transformedData.map((item) => {
        const flaggedCommentData = flaggedCommentsData.find(
          (fc) => fc.userId === item.userId
        );
        return {
          ...item,
          flaggedComments: flaggedCommentData?.flaggedComments || [],
        };
      });
      const filtered =
        selectedName && selectedName !== ''
          ? mergedData.filter((user) => user.userId === selectedName)
          : mergedData;

      setData(mergedData);
      setFilteredData(filtered);
      setSortBy('diff'); // Set default sort column
      setSortDirection('desc'); // Set default sort direction
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const [weekOptions, setWeekOptions] = useState([]);
  const generateWeeksForYear = (year = currentYear) => {
    const yearStart = startOfYear(new Date(`${year}-01-01`)); // Start of the given year
    const yearEnd = endOfYear(new Date(`${year}-12-31`)); // End of the given year
    const weeks = [];
    let currentWeekStart = startOfWeek(yearStart, { weekStartsOn: 1 });

    while (currentWeekStart <= yearEnd) {
      const weekEnd = endOfWeek(currentWeekStart, { weekStartsOn: 1 });
      const weekRange = `${format(currentWeekStart, 'yyyy-MM-dd')} - ${format(
        weekEnd,
        'yyyy-MM-dd'
      )}`;
      const weekNumber = format(currentWeekStart, 'w'); // Week number

      weeks.push({
        label: `Week ${weekNumber}: ${weekRange}`,
        value: weekRange,
        start: currentWeekStart,
        end: weekEnd,
      });

      currentWeekStart = addWeeks(currentWeekStart, 1);
    }

    return weeks;
  };
  useEffect(() => {
    const weeks = generateWeeksForYear();
    setWeekOptions(weeks);
  }, []);

  useEffect(() => {
    if (weekOptions.length > 0) {
      const currentWeek = weekOptions.find(
        (week) => week.start <= new Date() && week.end >= new Date()
      );

      const previousWeek = currentWeek
        ? weekOptions.find(
            (week) =>
              week.start.getTime() === subWeeks(currentWeek.start, 1).getTime()
          )
        : weekOptions[weekOptions.length - 1];

      console.log('previousWeek', previousWeek); // Log to check

      if (previousWeek) {
        setSelectedWeek(previousWeek.value); // Set the default selected week
        setWeekRange({ start: previousWeek.start, end: previousWeek.end });
      }
      handleSubmit();
    }
  }, [weekOptions]);

  useEffect(() => {
    handleSubmit();
  }, [selectedWeek]);

  const handleWeekChange = (event) => {
    const selectedValue = event.target.value;
    const dates = selectedValue.split(' - ');
    setSelectedWeek(selectedValue);
    console.log('Called Handle Week Chag', selectedValue);
    const selectedWeekObj = weekOptions.find(
      (week) => week.value === selectedValue
    );
    if (selectedWeekObj) {
      setSelectedWeek(selectedValue);
      setWeekRange({ start: selectedWeekObj.start, end: selectedWeekObj.end });
    }
  };

  const handleNextWeek = () => {
    if (weekRange.start && weekRange.end) {
      const nextWeekStart = addWeeks(weekRange.start, 1);
      const nextWeekEnd = addWeeks(weekRange.end, 1);
      const nextWeek = weekOptions.find(
        (week) =>
          week.start.getTime() === nextWeekStart.getTime() &&
          week.end.getTime() === nextWeekEnd.getTime()
      );

      if (nextWeek) {
        console.log('nextWeek', nextWeek.value);
        setSelectedWeek(nextWeek.value);
        setWeekRange({ start: nextWeek.start, end: nextWeek.end });
      }
    }
  };

  const handlePreviousWeek = () => {
    if (weekRange.start && weekRange.end) {
      const prevWeekStart = subWeeks(weekRange.start, 1);
      const prevWeekEnd = subWeeks(weekRange.end, 1);
      const prevWeek = weekOptions.find(
        (week) =>
          week.start.getTime() === prevWeekStart.getTime() &&
          week.end.getTime() === prevWeekEnd.getTime()
      );

      if (prevWeek) {
        setSelectedWeek(prevWeek.value);
        setWeekRange({ start: prevWeek.start, end: prevWeek.end });
      }
    }
  };

  const handleProjectFilter = (projectId) => {
    setSelectedProject(projectId);
    let filteredNames;

    if (projectId) {
      // Filter data based on selected project
      const projectFilteredData = data.filter((user) =>
        user.projects.some((project) => project.projectId === projectId)
      );
      setFilteredData(projectFilteredData);

      // Filter names based on selected project
      filteredNames = users.filter((user) =>
        projectFilteredData.some((userData) => userData.userId === user.id)
      );
    } else {
      setFilteredData(data); // Reset to all data
      filteredNames = users; // Reset to all names
    }

    setFilteredUsers(filteredNames);
    setSelectedName(''); // Reset name filter
  };

  const handleNameFilter = (userId) => {
    setSelectedName(userId);

    if (userId) {
      const nameFilteredData = data.filter((user) => user.userId === userId);

      if (selectedProject) {
        const projectFilteredData = nameFilteredData.filter((user) =>
          user.projects.some((project) => project.projectId === selectedProject)
        );
        setFilteredData(projectFilteredData);
      } else {
        setFilteredData(nameFilteredData);
      }
    } else {
      handleProjectFilter(selectedProject);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Calculate the previous week's start and end dates
        const today = new Date();
        const lastWeekStart = startOfWeek(today, { weekStartsOn: 1 }); // Monday
        const lastWeekEnd = endOfWeek(today, { weekStartsOn: 1 }); // Sunday

        lastWeekStart.setDate(lastWeekStart.getDate() - 7); // Go to the previous week's Monday
        lastWeekEnd.setDate(lastWeekEnd.getDate() - 7); // Go to the previous week's Sunday

        // Format the dates
        const formatDate = (date) => {
          const yyyy = date.getFullYear();
          const mm = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
          const dd = String(date.getDate()).padStart(2, '0');
          return `${yyyy}-${mm}-${dd}`;
        };

        const formattedStartDate = formatDate(lastWeekStart);
        const formattedEndDate = formatDate(lastWeekEnd);

        // Set the calculated dates
        setStartDate(lastWeekStart);
        setEndDate(lastWeekEnd);
        setSelectedWeek(lastWeekStart);

        // Fetch projects
        const projectResponse = await axios.get(
          'https://timesheet-be.fleetstudio.com/api/project/projects'
        );
        const activeProjects = projectResponse.data.filter(
          (project) => project.status === 'active'
        );
        setProjects(activeProjects);

        // Fetch users
        const userResponse = await axios.get(
          'https://timesheet-be.fleetstudio.com/api/user/getUsers'
        );
        const allUsers = userResponse.data
          .filter((user) => user.isActive) // Only include active users
          .map((user) => ({
            id: user._id,
            name: `${user.fname} ${user.lname}`.trim(),
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        setUsers(allUsers);
        setFilteredUsers(allUsers);

        // Fetch utilization data for the calculated week
        const apiUrl = `https://timesheet-be.fleetstudio.com/api/user/reports/filter/${formattedStartDate}/${formattedEndDate}`;
        const response = await axios.get(apiUrl);

        const rawData = response.data.map((item) => ({
          ...item,
          name: item.name,
        }));

        const formatNumber = (value) =>
          Number.isInteger(value) ? value : parseFloat(value).toFixed(1);

        const transformedData = rawData
          .filter((item) => item.isActive) // Include only active users
          .map((item, index) => {
            const diff =
              item.loggedHours -
              item.flaggedHours -
              (item.allocatedHours - item.holiday); // Calculate diff outside the object

            return {
              sNo: index + 1,
              name: item.name,
              userId: item.userId,
              type: item.employementStatus,
              allocated: formatNumber(item.allocatedHours), // Conditional formatting
              logged: formatNumber(item.loggedHours),
              holiday: formatNumber(item.holiday),
              leave: formatNumber(item.leave),
              learning: formatNumber(item.learning),
              flagged: formatNumber(item.flaggedHours),
              allocatedMinusHoliday: formatNumber(
                item.allocatedHours - item.holiday
              ), // Calculated column
              loggedMinusFlagged: formatNumber(
                item.loggedHours - item.flaggedHours
              ), // Calculated column
              diff: formatNumber(diff), // Store raw number
              comment: item.comment,
            };
          })
          .sort((a, b) => b.diff - a.diff);
        // Fetch flagged comments
        const flaggedCommentsData = await fetchFlaggedComments(
          formattedStartDate,
          formattedEndDate
        );

        // Merge flagged comments with utilization data
        const mergedData = transformedData.map((item) => {
          const flaggedCommentData = flaggedCommentsData.find(
            (fc) => fc.userId === item.userId
          );
          return {
            ...item,
            flaggedComments: flaggedCommentData?.flaggedComments || [],
          };
        });

        setData(mergedData);
        setFilteredData(mergedData); // Initialize with all data
        setSortBy('diff'); // Set default sort column
        setSortDirection('desc'); // Set default sort direction
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchInitialData();
  }, []);

  const handleSort = (key) => {
    const newDirection =
      sortBy === key && sortDirection === 'asc' ? 'desc' : 'asc';
    const sortedData = sortData(filteredData, key, newDirection);
    setSortBy(key);
    setSortDirection(newDirection);
    setFilteredData(sortedData);
  };

  const sortData = (data, key, direction) => {
    return [...data].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];

      // Convert string numbers to actual numbers for numerical keys
      if (!isNaN(aValue) && !isNaN(bValue)) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      // Handle numeric comparison for keys like "flagged"
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // Handle string comparison for other cases
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return 0;
    });
  };
  const fetchFlaggedComments = async (start, end) => {
    try {
      const response = await axios.get(
        `https://timesheet-be.fleetstudio.com/api/user/reports/getComments/${start}/${end}`
      );

      // Filter out users without valid flagged comments
      return response.data.data
        .filter(
          (item) =>
            item.flagComment &&
            item.flagComment.some((fc) => fc.query && fc.query.trim())
        )
        .map((item) => ({
          userId: item.uid, // Ensure `uid` matches `userId` in main data
          flaggedComments: item.flagComment
            .filter((fc) => fc.query && fc.query.trim()) // Only include valid comments
            .map((fc) => fc.query),
        }));
    } catch (error) {
      console.error('Error fetching flagged comments:', error);
      return [];
    }
  };
  const renderSortIcon = (key) => {
    const isCurrentColumn = sortBy === key;
    return (
      <span style={{ marginLeft: '8px', display: 'inline-block' }}>
        {isCurrentColumn && sortDirection === 'asc' ? (
          <FaArrowUp />
        ) : (
          <FaArrowDown />
        )}
      </span>
    );
  };

  const renderChart = () => {
    if (!filteredData.length) return null;

    const isSingleUser = !!selectedName;
    let filteredChartData;
    if (isSingleUser) {
      // For a single user, show all data without applying over/underutilized filters
      filteredChartData = filteredData;
    } else {
      filteredChartData =
        selectedOption === 'top5Over' || selectedOption === 'top10Over'
          ? filteredData.filter(
              (item) =>
                parseFloat(item.diff) > 0 &&
                item.type.toLowerCase() !== 'freelancer' // Exclude freelancers
            ) // Overutilized: Positive diff only
          : filteredData.filter(
              (item) =>
                parseFloat(item.diff) < 0 &&
                item.type.toLowerCase() !== 'freelancer' // Exclude freelancers
            ); // Underutilized: Negative diff only
    }

    const sortedData =
      selectedOption === 'top5Over' || selectedOption === 'top10Over'
        ? [...filteredChartData].sort((a, b) => b.diff - a.diff)
        : [...filteredChartData].sort((a, b) => a.diff - b.diff);

    // Select data based on menu option
    const topData =
      selectedOption === 'top5Over' || selectedOption === 'top5Under'
        ? sortedData.slice(0, 5)
        : sortedData.slice(0, 10);

    // Prepare the chart data
    const chartData = topData.map((user) => ({
      name: user.name,
      billable: user.billable,
      allocatedHours: user.allocatedHours,
      diff: user.diff,
    }));
    const maxDiff = Math.max(...chartData.map((item) => item.diff), 0);
    const minDiff = Math.min(...chartData.map((item) => item.diff), 0);

    return (
      <div
        className="min-h-[450px]"
        style={{ width: '100%', marginTop: '20px' }}
      >
        {/* Title */}
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ fontSize: '30px', fontWeight: 'bold' }}
          >
            User Utilization Overview
          </Typography>
        </div>

        {/* Menu Bar */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <FormControl variant="outlined" style={{ minWidth: 200 }}>
            <InputLabel>Choose</InputLabel>
            <Select
              value={selectedOption}
              onChange={handleMenuChange}
              label="Menu"
            >
              <MenuItem value="top5Over">Top 5 Over Utilized</MenuItem>
              <MenuItem value="top10Over">Top 10 Over Utilized</MenuItem>
              <MenuItem value="top5Under">Top 5 Under Utilized</MenuItem>
              <MenuItem value="top10Under">Top 10 Under Utilized</MenuItem>
            </Select>
          </FormControl>
        </div>
        {!filteredChartData.length ? (
          <Typography
            variant="h6"
            style={{
              textAlign: 'center',
              marginTop: '20px',
              color: '#888',
            }}
          >
            {selectedOption === 'top5Over' || selectedOption === 'top10Over'
              ? 'No over-utilized resources found.'
              : 'No under-utilized resources found.'}
          </Typography>
        ) : (
          <ResponsiveContainer width="100%" minHeight="350px">
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                tick={{ fontSize: 12, angle: -30, textAnchor: 'end' }}
                interval={0}
                height={80}
              />
              <YAxis
                domain={[Math.floor(minDiff * 1.1), Math.ceil(maxDiff * 1.1)]}
                allowDecimals={true}
              />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="diff"
                fill={
                  selectedOption === 'top5Under' ||
                  selectedOption === 'top10Under'
                    ? '#fa5555'
                    : '#82ca9d'
                }
                name="Difference"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    );
  };

  return (
    <StyledContainer>
      <Title>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',
            width: '100%',
          }}
        >
          <div>
            {' '}
            <h1 className="sticky top-0 mb-4 text-3xl font-extrabold text-gray-900 dark:text-black md:text-5xl lg:text-6xl flex justify-center">
              <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                Utilization
              </span>
            </h1>
          </div>
          <div>
            {' '}
            <Button
              variant="contained"
              color="primary"
              onClick={handleDetailedPage}
            >
              View Detailed Utilization
            </Button>
          </div>
        </div>
      </Title>
      <div
        className="sticky top-0 bg-white z-10"
        style={{
          display: 'flex',
          marginBottom: '20px',
          paddingBottom: '8px',
          paddingTop: '10px',
        }}
      >
        {/* Filters */}
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Select Week"
            views={['year', 'month', 'day']}
            value={selectedWeek}
            onChange={(newValue) => setSelectedWeek(newValue)}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="yyyy-MM-dd"
            minDate={new Date('2024-11-25')}
          />
        </LocalizationProvider> */}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Container display="flex" gap={2} direction="column">
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              style={{
                width: 500,
                alignItems: 'center',
              }}
            >
              <Button variant="contained" onClick={handlePreviousWeek}>
                Previous
              </Button>
              <Select
                value={selectedWeek}
                onChange={handleWeekChange}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select a week range
                </MenuItem>
                {weekOptions.map((week) => (
                  <MenuItem key={week.value} value={week.value}>
                    {week.label}
                  </MenuItem>
                ))}
              </Select>
              <Button variant="contained" onClick={handleNextWeek}>
                Next
              </Button>
            </Box>
            <Box mt={2}>
              <strong>Selected Week Range:</strong>{' '}
              {`${format(weekRange.start, 'yyyy-MM-dd')} - ${format(
                weekRange.end,
                'yyyy-MM-dd'
              )}`}
            </Box>
            <div className="gap-10 flex">
              <button
                className="text-yellow-500 font-bold"
                onClick={() => setYearRange((prevYear) => prevYear - 1)}
              >
                Previous Year
              </button>
              {yearRange < currentYear && (
                <button
                  className="text-blue-500 font-bold"
                  onClick={() => setYearRange((prevYear) => prevYear + 1)}
                >
                  Next Year
                </button>
              )}
            </div>
          </Container>
        </LocalizationProvider>
        {/* use the below form incase a project filter is needed */}
        {/* <FormControl
          variant="outlined"
          style={{ minWidth: 200 }}
          disabled={data.length === 0}
        >
          <InputLabel>Project</InputLabel>
          <Select
            value={selectedProject}
            onChange={(e) => handleProjectFilter(e.target.value)}
            label="Project"
          >
            <MenuItem value="">All Projects</MenuItem>
            {projects.map((project) => (
              <MenuItem key={project._id} value={project._id}>
                {project.projectName}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <div className="flex  gap-5 justify-center items-center mb-10">
          <FormControl
            variant="outlined"
            style={{ minWidth: 350 }}
            disabled={data.length === 0}
          >
            <InputLabel>User</InputLabel>
            <Select
              value={selectedName}
              onChange={(e) => handleNameFilter(e.target.value)}
              label="Name"
            >
              <MenuItem value="">All Users</MenuItem>
              {data.map((user) => (
                <MenuItem key={user.userId} value={user.userId}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            className="h-[40px]"
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading || !selectedWeek}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Submit'
            )}
          </Button>
        </div>
      </div>
      <div>{renderChart()}</div>

      {filteredData.length > 0 && (
        <>
          <div>
            <TableContainer>
              <Paper
                style={{
                  borderRadius: '12px',
                  overflowY: 'auto',
                  height: '100%',
                  // Ensure table fills available space
                }}
              >
                <Table
                  sx={{
                    borderCollapse: 'collapse', // Enforce collapse for proper row borders
                  }}
                  stickyHeader
                  // style={{
                  //   borderCollapse: "collapse",
                  //   width: "100%",
                  // }}
                >
                  <TableHead
                    style={{
                      backgroundColor: '#f1f1f1',
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    <TableRow
                      style={{
                        backgroundColor: '#f1f1f1',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      <StyledTableCell>S.No</StyledTableCell>
                      <StyledTableCell>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px',

                            fontWeight: 'bold',
                          }}
                          title="Name"
                        >
                          NAME
                        </div>
                      </StyledTableCell>
                      <StyledTableCell title="Type">Type</StyledTableCell>
                      <StyledTableCell
                        title="Logged Hours"
                        style={{ fontWeight: 'bold' }}
                      >
                        LOG
                      </StyledTableCell>
                      <StyledTableCell
                        title="Holiday"
                        style={{ fontWeight: 'bold' }}
                      >
                        HOL
                      </StyledTableCell>
                      <StyledTableCell
                        title="Leave"
                        style={{ fontWeight: 'bold' }}
                      >
                        LV
                      </StyledTableCell>
                      <StyledTableCell
                        title="Learning"
                        style={{ fontWeight: 'bold' }}
                      >
                        LRN
                      </StyledTableCell>
                      <StyledTableCell>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                          onClick={() => handleSort('flagged')}
                          title="Flagged Hours"
                        >
                          FLG {renderSortIcon('flagged')}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell title="Allocated - Holiday">
                        ALLOC
                      </StyledTableCell>
                      <StyledTableCell>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%', // Ensure alignment with other column headings
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                              display: 'block',
                              textAlign: 'center',
                              lineHeight: '1.5',
                            }}
                          >
                            ADJ LOG*
                          </span>
                          <Typography
                            variant="caption"
                            style={{
                              fontSize: '10px',
                              textAlign: 'center',
                              color: '#555',
                              lineHeight: '1.2',
                              marginTop: '4px', // Spacing between title and note
                            }}
                          >
                            LOG - (FLG + LV + LRN)
                          </Typography>
                        </div>
                      </StyledTableCell>

                      <StyledTableCell>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                          onClick={() => handleSort('diff')}
                          title="Difference"
                        >
                          DIFF {renderSortIcon('diff')}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        title="Details"
                        style={{ fontWeight: 'bold', textAlign: 'left' }}
                      >
                        DETAILS
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((row, idx) => (
                      <TableRow
                        key={idx}
                        onClick={() => handleRowClick(row)}
                        style={{
                          borderCollapse: 'collapse',
                          border:
                            row.flagged > 0
                              ? '2px solid #e63946'
                              : '1px solid transparent', // Bright red thick dotted border for flagged rows
                          borderRadius: row.flagged > 0 ? '8px' : '0', // Rounded corners for flagged rows
                          boxShadow:
                            row.flagged > 0
                              ? '0 0 5px 2px rgba(230, 57, 70, 0.5)'
                              : 'none', // Subtle shadow for flagged rows
                          transition: 'all 0.3s ease-in-out',
                          backgroundColor: calculateBackgroundColor(row.diff), // Use your existing function to style the background
                        }}
                      >
                        <TableCell
                          style={{ padding: '10px', textAlign: 'center' }}
                        >
                          {idx + 1}
                        </TableCell>
                        <TableCell style={{ padding: '10px' }}>
                          {row.name}
                        </TableCell>
                        <TableCell style={{ padding: '10px' }}>
                          {row.type}
                        </TableCell>

                        <TableCell
                          style={{
                            padding: '10px',
                            textAlign: 'center',
                          }}
                        >
                          {row.logged}
                        </TableCell>
                        <TableCell
                          style={{
                            padding: '10px',
                            textAlign: 'center',
                          }}
                        >
                          {row.holiday}
                        </TableCell>
                        <TableCell
                          style={{
                            padding: '10px',
                            textAlign: 'center',
                          }}
                        >
                          {row.leave}
                        </TableCell>
                        <TableCell
                          style={{
                            padding: '10px',
                            textAlign: 'center',
                          }}
                        >
                          {row.learning}
                        </TableCell>
                        <TableCell
                          style={{
                            padding: '10px',
                            textAlign: 'center',
                            color: row.flagged > 0 ? 'red' : 'inherit',
                            fontWeight: row.flagged > 0 ? 'bold' : 'normal',
                          }}
                        >
                          {row.flagged}
                        </TableCell>
                        <TableCell
                          style={{
                            padding: '10px',
                            textAlign: 'center',
                          }}
                        >
                          {row.allocatedMinusHoliday}
                        </TableCell>
                        <TableCell
                          style={{
                            padding: '10px',
                            textAlign: 'center',
                          }}
                        >
                          {row.loggedMinusFlagged}
                        </TableCell>
                        <TableCell
                          style={{
                            padding: '10px',
                            textAlign: 'center',
                          }}
                        >
                          {row.diff}
                        </TableCell>
                        <TableCell
                          style={{
                            padding: '10px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            // display: "flex",
                            justifyContent: 'center',
                            alignItems: 'center', // Ensures vertical alignment
                            gap: '8px', // Add spacing between icons
                          }}
                          onClick={() => handleRowClick(row)} // Open the existing popup
                          title="Click to view comments"
                        >
                          {row.comment.filter((cmt) => cmt.trim()).length ===
                            0 &&
                            row.flaggedComments.filter((fcmt) => fcmt.trim())
                              .length === 0 && (
                              // Blue Info Circle when no comments
                              <FaInfoCircle
                                style={{ color: 'blue', fontSize: '1rem' }}
                              />
                            )}

                          {row.comment.filter((cmt) => cmt.trim()).length > 0 &&
                            row.flaggedComments.filter((fcmt) => fcmt.trim())
                              .length === 0 && (
                              // Blue Info Circle with Orange Flag when regular comments exist
                              <div style={{ display: 'flex', gap: '4px' }}>
                                <FaInfoCircle
                                  style={{ color: 'blue', fontSize: '1rem' }}
                                />
                                <FaFlag
                                  style={{ color: 'orange', fontSize: '1rem' }}
                                />
                              </div>
                            )}

                          {row.comment.filter((cmt) => cmt.trim()).length ===
                            0 &&
                            row.flaggedComments.filter((fcmt) => fcmt.trim())
                              .length > 0 && (
                              // Blue Info Circle with Red Flag when flagged comments exist
                              <div style={{ display: 'flex', gap: '4px' }}>
                                <FaInfoCircle
                                  style={{ color: 'blue', fontSize: '1rem' }}
                                />
                                <FaFlag
                                  style={{ color: 'red', fontSize: '1rem' }}
                                />
                              </div>
                            )}

                          {row.comment.filter((cmt) => cmt.trim()).length > 0 &&
                            row.flaggedComments.filter((fcmt) => fcmt.trim())
                              .length > 0 && (
                              // Blue Info Circle with Red and Orange Flags when both comments exist
                              <div style={{ display: 'flex', gap: '4px' }}>
                                <FaInfoCircle
                                  style={{ color: 'blue', fontSize: '1rem' }}
                                />
                                <FaFlag
                                  style={{ color: 'red', fontSize: '1rem' }}
                                />

                                <FaFlag
                                  style={{ color: 'orange', fontSize: '1rem' }}
                                />
                              </div>
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
              {/* Dialog Box */}
              <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                maxWidth="sm"
                fullWidth
                style={{ borderRadius: '25px' }}
              >
                <DialogTitle
                  style={{
                    background: 'linear-gradient(90deg, #358FBF, #005f73)',
                    color: '#fff',
                    textAlign: 'center',
                    padding: '20px 16px',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                  }}
                >
                  Details
                  {startDate && endDate && (
                    <span
                      style={{
                        fontSize: '1rem',
                        fontWeight: 'normal',
                        marginLeft: '10px',
                        color: '#cce7f3',
                      }}
                    >
                      ({startDate.toLocaleDateString()} -{' '}
                      {endDate.toLocaleDateString()})
                    </span>
                  )}
                </DialogTitle>

                <DialogContent
                  style={{
                    backgroundColor: '#f9f9f9',
                    padding: '24px',
                  }}
                >
                  {fetching ? ( // Show fetching state if true
                    <Typography
                      variant="body1"
                      style={{
                        textAlign: 'center',
                        color: '#888',
                        fontStyle: 'italic',
                      }}
                    >
                      Fetching data...
                    </Typography>
                  ) : (
                    selectedRow && (
                      <div>
                        <div
                          style={{
                            marginBottom: '16px',
                            padding: '16px',
                            background: '#e6f2f9',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: 'bold',
                              color: '#005f73',
                            }}
                          >
                            {selectedRow.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 'bold',
                              color: '#333',
                            }}
                          >
                            {selectedRow.type}
                          </Typography>
                        </div>

                        {/* Comments Section */}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '12px',
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: 'bold',
                              color: '#005f73',
                            }}
                          >
                            Comments
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{
                              fontStyle: 'italic',
                              color: '#888',
                            }}
                          >
                            * Black: general, Red: flagged
                          </Typography>
                        </div>

                        {(selectedRow.comment &&
                          selectedRow.comment.filter((cmt) => cmt.trim())
                            .length > 0) ||
                        (selectedRow.flaggedComments &&
                          selectedRow.flaggedComments.filter((fcmt) =>
                            fcmt.trim()
                          ).length > 0) ? (
                          <div>
                            {selectedRow.comment &&
                              selectedRow.comment.filter((cmt) => cmt.trim())
                                .length > 0 && (
                                <ul
                                  style={{
                                    listStyleType: 'disc',
                                    paddingLeft: '16px',
                                  }}
                                >
                                  {selectedRow.comment
                                    .filter((cmt) => cmt.trim())
                                    .map((cmt, i) => (
                                      <li
                                        key={i}
                                        style={{
                                          marginBottom: '8px',
                                          color: '#333',
                                        }}
                                      >
                                        <Typography variant="body2">
                                          {cmt}
                                        </Typography>
                                      </li>
                                    ))}
                                </ul>
                              )}
                            {selectedRow.flaggedComments &&
                              selectedRow.flaggedComments.filter((fcmt) =>
                                fcmt.trim()
                              ).length > 0 && (
                                <ul
                                  style={{
                                    listStyleType: 'disc',
                                    paddingLeft: '16px',
                                  }}
                                >
                                  {selectedRow.flaggedComments
                                    .filter((fcmt) => fcmt.trim())
                                    .map((fcmt, i) => (
                                      <li
                                        key={i}
                                        style={{
                                          marginBottom: '8px',
                                          color: 'red',
                                        }}
                                      >
                                        <Typography variant="body2">
                                          {fcmt}
                                        </Typography>
                                      </li>
                                    ))}
                                </ul>
                              )}
                          </div>
                        ) : (
                          <Typography
                            variant="body2"
                            style={{ color: '#888', fontStyle: 'italic' }}
                          >
                            No comments available
                          </Typography>
                        )}

                        {/* Project Details Section */}
                        <Typography
                          variant="h6"
                          style={{
                            marginBottom: '12px',
                            fontWeight: 'bold',
                            color: '#005f73',
                            paddingTop: '10px',
                          }}
                        >
                          Project Details
                        </Typography>
                        <ul
                          style={{ listStyleType: 'disc', paddingLeft: '16px' }}
                        >
                          {projectDetails.length > 0 ? (
                            projectDetails.map(([projectName, hours], i) => (
                              <li
                                key={i}
                                style={{ marginBottom: '8px', color: '#333' }}
                              >
                                <Typography variant="body2">
                                  {projectName}: {hours} hours
                                </Typography>
                              </li>
                            ))
                          ) : (
                            <Typography
                              variant="body2"
                              style={{ color: '#888' }}
                            >
                              No project details available.
                            </Typography>
                          )}
                        </ul>
                      </div>
                    )
                  )}
                </DialogContent>
                <DialogActions
                  style={{
                    background: 'linear-gradient(90deg, #358FBF, #005f73)',
                    padding: '12px',
                  }}
                >
                  <Button
                    onClick={handleDialogClose}
                    style={{
                      color: '#fff',
                      backgroundColor: '#005f73',
                      fontWeight: 'bold',
                      textTransform: 'none',
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </TableContainer>
          </div>
        </>
      )}
    </StyledContainer>
  );
};

export default Utilization;

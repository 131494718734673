import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import AttendanceCalendar from './attendanceCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Select, MenuItem, TextField, InputLabel, FormControl } from '@mui/material';
import { styled } from '@mui/system';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ProgressLoader from '../utensils/ProgressLoader';
import userMappingFirstNames from '../utensils/Name';

dayjs.extend(utc);
dayjs.extend(timezone);

const StyledContainer = styled('div')({
  backgroundColor: '#FFFFFF',
  padding: '30px',
  borderRadius: '16px',
  boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1)',
  width: '100%',
  minHeight: '100vh',
  margin: '0 auto',
  boxSizing: 'border-box',
});

const Title = styled("h1")({
  textAlign: "center",
  color: "#0d47a1",
  marginBottom: "30px",
  fontSize: "2.5rem",
  fontFamily: "Roboto, sans-serif",
});

const AttendanceByUser = () => {
  const [availableUsers, setAvailableUsers] = useState([]); // Users for selected date range
  const [selectedUser, setSelectedUser] = useState('');
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30); // Subtract 30 days
    return date;
  });
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    return date;
  });
  const [loading, setLoading] = useState(true);

  // Fetch users available in the selected date range
  useEffect(() => {
    if (startDate && endDate) {
      fetchUsersInRange();
    }
  }, [startDate, endDate]);

  const fetchUsersInRange = async () => {
    setLoading(true);
    try {
      // Fetch users present within the date range
      const response = await axios.get(`${BASE_URL}/active-users-in-range?startDateTime=${startDate.toISOString()}&endDateTime=${endDate.toISOString()}`);
      const userIdsInRange = response.data; // Expect this to return a list of user IDs
      setAvailableUsers(userIdsInRange); // Set available users as IDs
    } catch (error) {
      console.error('Error fetching users in range:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContainer>
      <Title>
        <h1 className="sticky top-0 mb-2 bg-white text-3xl  text-gray-900 dark:text-black md:text-5xl lg:text-6xl pb-3">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
            User
          </span> Attendance
        </h1>
        <div style={{ borderBottom: '2px solid #0d47a1' }}></div>
      </Title>

      <div className="sticky top-0 mb-2 bg-white flex flex-col md:flex-row md:items-center mb-6 space-y-4 md:space-y-0 md:space-x-4 pt-5">
        <FormControl fullWidth variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel id="user-select-label">Select User</InputLabel>
          <Select
            labelId="user-select-label"
            value={selectedUser}
            onChange={(event) => setSelectedUser(event.target.value)}
            label="Select User"
          >
            <MenuItem value="">
              <em>Select User</em>
            </MenuItem>
            {availableUsers.map((userId) => (
              <MenuItem key={userId} value={userId}>
                {userMappingFirstNames[userId] }  {/* Display userId directly */}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />

        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>

      {selectedUser && startDate && endDate ? (
        <>
          {loading ? (
            <ProgressLoader />
          ) : (
            <>
              <div className="bg-white  rounded-lg shadow-lg mt-6 flex justify-center">
                <AttendanceCalendar userId={selectedUser} startDate={startDate} endDate={endDate} />
              </div>
            </>
          )}
        </>
      ) : (
        <p className="text-red-500 text-lg mt-5">Please select a user and a date range.</p>
      )}
    </StyledContainer>
  );
};

export default AttendanceByUser;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { FaInfoCircle, FaFlag } from 'react-icons/fa';
// import { styled } from "@mui/system";
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

import {
  Container,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Switch,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import { getCurrentWeekNumber, getMonthRanges, getWeekRanges } from './Utils';
import { is } from 'date-fns/locale';
import { RiLoader4Fill } from 'react-icons/ri';

// Styled Header and Container
const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
  padding: '30px',
  borderRadius: '16px',
  boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1)',
  maxWidth: '100%',
});

const Title = styled('h1')({
  textAlign: 'center',
  color: '#0d47a1',
  marginBottom: '30px',
  fontSize: '2.5rem',
  fontFamily: 'Roboto, sans-serif',
  borderBottom: '2px solid #0d47a1',
  paddingBottom: '10px',
});
const CompactSubTitle = styled('span')({
  display: 'block',
  fontSize: '1rem',
  color: '#555',
  fontWeight: 'normal',
  marginTop: '10px',
});

const TableContainer = styled('div')({
  marginTop: '20px',
  border: '1px solid #ddd',
  borderRadius: '12px',
  overflowY: 'auto',
});

// Function to calculate background color based on utilization
const calculateBackgroundColor = (diff) => {
  if (diff > 0) {
    return `rgba(144, 238, 144, ${Math.min(0.2 + diff / 100, 0.8)})`;
  } else if (diff < 0) {
    return `rgba(255, 99, 71, ${Math.min(0.2 + Math.abs(diff) / 100, 0.8)})`;
  }
  return 'transparent';
};

// Detailed Utilization Component
const DetailedUtilization = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [usersWithMultipleProjects, setUsersWithMultipleProjects] = useState(
    []
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [flaggedCommentsData, setFlaggedCommentsData] = useState([]);
  const [peopleWithComments, setPeopleWithComments] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [projectDetails, setProjectDetails] = useState([]); // State for project details
  const [fetching, setFetching] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fsLabsData, setFsLabsData] = useState([]);
  const [reportType, setReportType] = useState('weekly');

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };
  const [sortConfig, setSortConfig] = useState({
    key: 'name',
    direction: 'asc',
  });
  <style>
    {`
          @media print {
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid #ddd !important;
              padding: 8px !important;
              text-align: center;
            }
            thead {
              background-color: #f1f1f1 !important;
              -webkit-print-color-adjust: exact !important;
              color-adjust: exact !important;
            }
            tr:nth-child(even) {
              background-color: #f9f9f9 !important;
            }
            tr:nth-child(odd) {
              background-color: #ffffff !important;
            }
            body {
              -webkit-print-color-adjust: exact !important;
              color-adjust: exact !important;
            }
          }
        `}
  </style>;
  useEffect(() => {
    const fetchTimesheetData = async () => {
      if (startDate == null || endDate == null) {
        return;
      }
      setIsLoading(true);

      try {
        const response = await axios.get(
          `https://timesheet-be.fleetstudio.com/api/user/reports/getFSLabsTimesheetInRange/${startDate}/${endDate}`
        ); // Replace with your API endpoint
        setFsLabsData(response.data.data);
      } catch (err) {
        console.log('Error', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTimesheetData();
  }, [startDate, endDate]);
  const formatNumber = (value) =>
    Number.isInteger(value) ? value : parseFloat(value).toFixed(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startDate = "2024-12-09";
        // const endDate = "2024-12-15";

        // Fetch main report data
        if (startDate == null || endDate == null) {
          return;
        }
        setIsLoading(true);

        const reportApiUrl = `https://timesheet-be.fleetstudio.com/api/user/reports/filter/${startDate}/${endDate}`;

        const reportResponse = await axios.get(reportApiUrl);

        const transformedData = reportResponse.data
          .filter((item) => item.isActive)
          .map((item, index) => ({
            sNo: index + 1,
            name: item.name,
            userId: item.userId,
            type: item.employementStatus,
            allocated: formatNumber(item.allocatedHours), // Conditional formatting
            logged: formatNumber(item.loggedHours),
            holiday: formatNumber(item.holiday),
            leave: formatNumber(item.leave),
            learning: formatNumber(item.learning),
            flagged: formatNumber(item.flaggedHours),
            // allocatedMinusHoliday: formatNumber(
            //   item.allocatedHours - item.holiday
            // ), // Calculated column
            allocatedMinusHoliday: formatNumber(item.allocatedHours), // Calculated column
            loggedMinusFlagged: formatNumber(
              item.loggedHours -
                item.flaggedHours -
                item.leave -
                item.learning -
                item.holiday
            ), // Calculated column
            // diff: formatNumber(
            //   formatNumber(
            //     item.loggedHours -
            //       item.flaggedHours -
            //       item.leave -
            //       item.learning -
            //       item.holiday
            //   ) -
            //     formatNumber(item.allocatedHours) -
            //     formatNumber(item.holiday)
            // ), // Store raw number
            diff: formatNumber(
              item.loggedHours -
                item.flaggedHours -
                item.leave -
                item.learning -
                item.holiday -
                item.allocatedHours
            ), // Store raw number
            comment: Array.isArray(item.comment) // Ensure it's an array
              ? item.comment.map(String) // Convert each entry to string
              : item.comment
              ? [String(item.comment)] // Wrap single comment as an array
              : [], // Default to an empty array
            flaggedComments: [], // Initialize as an empty array
          }))
          .sort((a, b) => {
            // Sort by `diff` descending, then push rows with `diff === 0` to the end
            if (a.diff === 0 && b.diff === 0) return 0;
            if (a.diff === 0) return 1;
            if (b.diff === 0) return -1;
            return b.diff - a.diff; // Sort descending
          });
        console.log('Data', data);
        setData(transformedData);
        if (startDate == null || endDate == null) {
          return;
        }
        // Fetch timesheet data for multiple projects
        const timesheetApiUrl = `https://timesheet-be.fleetstudio.com/api/timesheet/getTimeSheets?startDate=${startDate}&endDate=${endDate}`;
        setIsLoading(true);
        const timesheetResponse = await axios.get(timesheetApiUrl);

        const ignoredProjectIds = [
          '66f68fda5cf392010e45f9f0',
          '66f68fb55cf392010e45f9db',
          '66f6946a5cf392010e45fad4',
          '66f68ffd5cf392010e45f9f4',
        ];

        const groupedData = timesheetResponse.data.reduce((acc, item) => {
          const userId = item.uid._id;
          const userName = `${item.uid.fname.trim()} ${item.uid.lname.trim()}`;
          const projectId = item.field_proj._id;
          const projectName = item.field_proj.projectName;
          const hours = parseFloat(item.field_time_spent) || 0;

          // Ignore specific projects
          if (ignoredProjectIds.includes(projectId)) return acc;

          if (!acc[userId]) {
            acc[userId] = { name: userName, projects: {} };
          }
          if (!acc[userId].projects[projectName]) {
            acc[userId].projects[projectName] = 0;
          }
          acc[userId].projects[projectName] += hours;

          return acc;
        }, {});

        const filteredUsers = Object.values(groupedData).filter(
          (user) => Object.keys(user.projects).length > 1
        );

        setUsersWithMultipleProjects(filteredUsers);
        if (startDate == null || endDate == null) {
          return;
        }
        const flaggedCommentsApiUrl = `https://timesheet-be.fleetstudio.com/api/user/reports/getComments/${startDate}/${endDate}`;
        setIsLoading(true);

        const flaggedResponse = await axios.get(flaggedCommentsApiUrl);

        const flaggedData = flaggedResponse.data.data.map((item) => ({
          uid: item.uid,
          flaggedComments: item.flagComment
            ? item.flagComment.map((fc) => fc.query)
            : [],
        }));
        setIsLoading(false);

        setFlaggedCommentsData(flaggedData);

        const fetchFlaggedComments = async (start, end) => {
          setIsLoading(true);

          try {
            const response = await axios.get(
              `https://timesheet-be.fleetstudio.com/api/user/reports/getComments/${start}/${end}`
            );

            // Filter out users without valid flagged comments
            return response.data.data
              .filter(
                (item) =>
                  item.flagComment &&
                  item.flagComment.some((fc) => fc.query && fc.query.trim())
              )
              .map((item) => ({
                userId: item.uid, // Ensure `uid` matches `userId` in main data
                flaggedComments: item.flagComment
                  .filter((fc) => fc.query && fc.query.trim()) // Only include valid comments
                  .map((fc) => fc.query),
              }));
          } catch (error) {
            console.error('Error fetching flagged comments:', error);
            return [];
          } finally {
            setIsLoading(false);
          }
        };
        const flaggedCommentsData2 = await fetchFlaggedComments(
          startDate,
          endDate
        );

        // Merge normal and flagged comments
        const mergedData = transformedData.map((item) => {
          const flaggedCommentData2 = flaggedCommentsData2.find(
            (fc) => fc.userId === item.userId
          );
          return {
            ...item,
            flaggedComments: flaggedCommentData2?.flaggedComments || [],
          };
        });
        setData(mergedData);

        setPeopleWithComments(
          mergedData.filter(
            (user) => user.comment.length > 0 || user.flaggedComments.length > 0
          )
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  // Sort handler
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (
        key === 'diff' ||
        key === 'allocated' ||
        key === 'logged' ||
        key === 'leave'
      ) {
        return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
      } else {
        return direction === 'asc'
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      }
    });
    setData(sortedData);
  };
  const handleRowClick = async (row) => {
    console.log('Row clicked:', row);
    setSelectedRow(row);
    setFetching(true);
    setDialogOpen(true);

    try {
      const userId = row.userId;
      // Function to format date in 'YYYY-MM-DD' without time zone shifts
      if (startDate == null || endDate == null) {
        return;
      }
      const apiUrl = `https://timesheet-be.fleetstudio.com/api/timesheet/getTimeSheets?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
      const response = await axios.get(apiUrl);

      // Transform data to get project names and hours
      const projectData = response.data.reduce((acc, entry) => {
        const { projectName } = entry.field_proj;
        const hours = parseFloat(entry.field_time_spent || 0);

        if (projectName) {
          if (acc[projectName]) {
            acc[projectName] += hours; // Accumulate hours
          } else {
            acc[projectName] = hours; // Initialize hours
          }
        }
        return acc;
      }, {});

      setProjectDetails(Object.entries(projectData)); // Convert object to array for rendering
      const commentsApiUrl = `https://timesheet-be.fleetstudio.com/api/user/reports/getComments/${startDate}/${endDate}/${userId}`;
      const commentsResponse = await axios.get(commentsApiUrl);

      const flaggedComments =
        commentsResponse.data?.data?.[0]?.flagComment?.map((c) => c.query) ||
        [];
      setSelectedRow((prevRow) => ({
        ...prevRow,
        flaggedComments,
      }));
      setFetching(false);
    } catch (error) {
      console.error('Error fetching project details:', error);
    }
  };
  // Render Full Table
  const renderFullTable = () => (
    <TableContainer style={{ marginTop: '60px' }}>
      <Paper>
        <h1 className="flex justify-center items-center text-5xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
          Utilization Table
        </h1>
        <div className="flex px-5 font-extrabold text-xs gap-10 bg-indigo-200  rounded-lg p-4 shadow-2xl w-[25rem] mx-4">
          <p className="font-extrabold">
            Available Hours (Avl) = {data?.[0]?.allocatedMinusHoliday}Hrs
          </p>
          <p className="font-extrabold">
            Holidays (Hol) = {data?.[0]?.holiday}Hrs
          </p>
        </div>
        <Table
          stickyHeader
          sx={{
            borderCollapse: 'collapse', // Enforce collapse for proper row borders
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                S.No
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                <TableSortLabel
                  active={sortConfig.key === 'name'}
                  direction={
                    sortConfig.key === 'name' ? sortConfig.direction : 'asc'
                  }
                  onClick={() => handleSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Log
              </TableCell>
              {/* <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Alloc
              </TableCell> */}
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Leave
              </TableCell>
              {/* <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Hol
              </TableCell> */}
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Lrn
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Flg
              </TableCell>
              <TableCell
                style={{
                  verticalAlign: 'top',
                  textAlign: 'center',
                  padding: '8px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%', // Ensure alignment with other column headings
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      display: 'block',
                      textAlign: 'center',
                      lineHeight: '1.5',
                    }}
                  >
                    Adj Log*
                  </span>
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: '10px',
                      textAlign: 'center',
                      color: '#555',
                      lineHeight: '1.2',
                      marginTop: '4px', // Spacing between title and note
                    }}
                  >
                    Log - (Flg + Leave + Lrn+ Hol)
                  </Typography>
                </div>
              </TableCell>

              <TableCell
                style={{
                  verticalAlign: 'top',
                  textAlign: 'center',
                  padding: '8px',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    display: 'block',
                    textAlign: 'center',
                    lineHeight: '1.5',
                  }}
                >
                  Diff
                </span>
                <TableSortLabel
                  active={sortConfig.key === 'diff'}
                  direction={
                    sortConfig.key === 'diff' ? sortConfig.direction : 'asc'
                  }
                  onClick={() => handleSort('diff')}
                  style={{
                    fontSize: '10px',
                    textAlign: 'center',
                    color: '#555',
                    lineHeight: '1.2',
                  }}
                >
                  Adj Log*-Avl
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  textAlign: 'left',
                }}
              >
                Dtl
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter((row) => row.type === 'Fulltime' || row.type === 'Intern') // Filter rows by type
              .map((row, index) => {
                const hasComments = row.comment.some((cmt) => cmt.trim());
                const hasFlaggedComments = row.flaggedComments.some((fcmt) =>
                  fcmt.trim()
                );

                const renderIcons = () => {
                  if (!hasComments && !hasFlaggedComments) {
                    return (
                      <FaInfoCircle
                        style={{ color: 'blue', fontSize: '1rem' }}
                      />
                    );
                  }

                  if (hasComments && !hasFlaggedComments) {
                    return (
                      <div style={{ display: 'flex', gap: '4px' }}>
                        <FaInfoCircle
                          style={{ color: 'blue', fontSize: '1rem' }}
                        />
                        <FaFlag style={{ color: 'orange', fontSize: '1rem' }} />
                      </div>
                    );
                  }

                  if (!hasComments && hasFlaggedComments) {
                    return (
                      <div style={{ display: 'flex', gap: '4px' }}>
                        <FaInfoCircle
                          style={{ color: 'blue', fontSize: '1rem' }}
                        />
                        <FaFlag style={{ color: 'red', fontSize: '1rem' }} />
                      </div>
                    );
                  }

                  if (hasComments && hasFlaggedComments) {
                    return (
                      <div style={{ display: 'flex', gap: '4px' }}>
                        <FaInfoCircle
                          style={{ color: 'blue', fontSize: '1rem' }}
                        />
                        <FaFlag style={{ color: 'red', fontSize: '1rem' }} />
                        <FaFlag style={{ color: 'orange', fontSize: '1rem' }} />
                      </div>
                    );
                  }
                };

                return (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(row)}
                    style={{
                      border:
                        row.flagged > 0
                          ? '2px solid #e63946'
                          : '1px solid transparent',
                      borderRadius: row.flagged > 0 ? '8px' : '0',
                      transition: 'all 0.3s ease-in-out',
                      backgroundColor: calculateBackgroundColor(row.diff),
                    }}
                  >
                    <TableCell style={{ textAlign: 'center' }}>
                      {index + 1}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center' }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Link
                        className="text-blue-600 underline"
                        to={`/dashboard/user-analysis?userId=${row.userId}&startDate=${startDate}&endDate=${endDate}`}
                      >
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {row.logged}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {row.leave}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {row.learning}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {row.flagged}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {row.loggedMinusFlagged}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {row.diff}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      {renderIcons()}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        style={{ borderRadius: '25px' }}
      >
        <DialogTitle
          style={{
            background: 'linear-gradient(90deg, #358FBF, #005f73)',
            color: '#fff',
            textAlign: 'center',
            padding: '20px 16px',
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
        >
          Details
          {startDate && endDate && (
            <span
              style={{
                fontSize: '1rem',
                fontWeight: 'normal',
                marginLeft: '10px',
                color: '#cce7f3',
              }}
            >
              ({startDate} - {endDate})
            </span>
          )}
        </DialogTitle>

        <DialogContent
          style={{
            backgroundColor: '#f9f9f9',
            padding: '24px',
          }}
        >
          {fetching ? ( // Show fetching state if true
            <Typography
              variant="body1"
              style={{
                textAlign: 'center',
                color: '#888',
                fontStyle: 'italic',
              }}
            >
              Fetching data...
            </Typography>
          ) : (
            selectedRow && (
              <div>
                <div
                  style={{
                    marginBottom: '16px',
                    padding: '16px',
                    background: '#e6f2f9',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 'bold',
                      color: '#005f73',
                    }}
                  >
                    <Link
                      to={`/dashboard/user-analysis?userId=${selectedRow.userId}&startDate=${startDate}&endDate=${endDate}`}
                    >
                      {selectedRow.name}
                    </Link>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 'bold',
                      color: '#333',
                    }}
                  >
                    {selectedRow.type}
                  </Typography>
                </div>

                {/* Comments Section */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '12px',
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 'bold',
                      color: '#005f73',
                    }}
                  >
                    Comments
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontStyle: 'italic',
                      color: '#888',
                    }}
                  >
                    * Black: general, Red: flagged
                  </Typography>
                </div>

                {(selectedRow.comment &&
                  selectedRow.comment.filter((cmt) => cmt.trim()).length > 0) ||
                (selectedRow.flaggedComments &&
                  selectedRow.flaggedComments.filter((fcmt) => fcmt.trim())
                    .length > 0) ? (
                  <div>
                    {selectedRow.comment &&
                      selectedRow.comment.filter((cmt) => cmt.trim()).length >
                        0 && (
                        <ul
                          style={{
                            listStyleType: 'disc',
                            paddingLeft: '16px',
                          }}
                        >
                          {selectedRow.comment
                            .filter((cmt) => cmt.trim())
                            .map((cmt, i) => (
                              <li
                                key={i}
                                style={{
                                  marginBottom: '8px',
                                  color: '#333',
                                }}
                              >
                                <Typography variant="body2">{cmt}</Typography>
                              </li>
                            ))}
                        </ul>
                      )}
                    {selectedRow.flaggedComments &&
                      selectedRow.flaggedComments.filter((fcmt) => fcmt.trim())
                        .length > 0 && (
                        <ul
                          style={{
                            listStyleType: 'disc',
                            paddingLeft: '16px',
                          }}
                        >
                          {selectedRow.flaggedComments
                            .filter((fcmt) => fcmt.trim())
                            .map((fcmt, i) => (
                              <li
                                key={i}
                                style={{
                                  marginBottom: '8px',
                                  color: 'red',
                                }}
                              >
                                <Typography variant="body2">{fcmt}</Typography>
                              </li>
                            ))}
                        </ul>
                      )}
                  </div>
                ) : (
                  <Typography
                    variant="body2"
                    style={{ color: '#888', fontStyle: 'italic' }}
                  >
                    No comments available
                  </Typography>
                )}

                {/* Project Details Section */}
                <Typography
                  variant="h6"
                  style={{
                    marginBottom: '12px',
                    fontWeight: 'bold',
                    color: '#005f73',
                    paddingTop: '10px',
                  }}
                >
                  Project Details
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '16px' }}>
                  {projectDetails.length > 0 ? (
                    projectDetails.map(([projectName, hours], i) => (
                      <li
                        key={i}
                        style={{ marginBottom: '8px', color: '#333' }}
                      >
                        <Typography variant="body2">
                          {projectName}: {hours} hours
                        </Typography>
                      </li>
                    ))
                  ) : (
                    <Typography variant="body2" style={{ color: '#888' }}>
                      No project details available.
                    </Typography>
                  )}
                </ul>
              </div>
            )
          )}
        </DialogContent>
        <DialogActions
          style={{
            background: 'linear-gradient(90deg, #358FBF, #005f73)',
            padding: '12px',
          }}
        >
          <Button
            onClick={handleDialogClose}
            style={{
              color: '#fff',
              backgroundColor: '#005f73',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );

  const RenderPartTimeFreelancer = () => (
    <TableContainer style={{ marginTop: '60px' }}>
      <Paper>
        <h1 className="flex justify-center items-center text-5xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
          Freelancers (hourly) / Consultants (fixed)
        </h1>

        <Table
          stickyHeader
          sx={{
            borderCollapse: 'collapse', // Enforce collapse for proper row borders
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                S.No
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                <TableSortLabel
                  active={sortConfig.key === 'name'}
                  direction={
                    sortConfig.key === 'name' ? sortConfig.direction : 'asc'
                  }
                  onClick={() => handleSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Type
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%', // Ensure alignment with other column headings
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      display: 'block',
                      textAlign: 'center',
                      lineHeight: '1.5',
                    }}
                  >
                    Adj Log*
                  </span>
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: '10px',
                      textAlign: 'center',
                      color: '#555',
                      lineHeight: '1.2',
                      marginTop: '4px', // Spacing between title and note
                    }}
                  >
                    Log - (Flg + Leave + Lrn+ Hol)
                  </Typography>
                </div>
              </TableCell>
              {/* <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Alloc
              </TableCell> 
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Leave
              </TableCell>
              {/* <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Hol
              </TableCell> 
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Lrn
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Flg
              </TableCell>
              */}
              {/* <TableCell
                style={{
                  verticalAlign: 'top',
                  textAlign: 'center',
                  padding: '8px',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    display: 'block',
                    textAlign: 'center',
                    lineHeight: '1.5',
                  }}
                >
                  Diff
                </span>
                <TableSortLabel
                  active={sortConfig.key === 'diff'}
                  direction={
                    sortConfig.key === 'diff' ? sortConfig.direction : 'asc'
                  }
                  onClick={() => handleSort('diff')}
                  style={{
                    fontSize: '10px',
                    textAlign: 'center',
                    color: '#555',
                    lineHeight: '1.2',
                  }}
                >
                  Adj Log*-Avl
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  textAlign: 'left',
                }}
              >
                Dtl
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter(
                (row) => row.type === 'Consultant' || row.type === 'Freelancer'
              ) // Filter rows by type
              .map((row, index) => {
                const hasComments = row.comment.some((cmt) => cmt.trim());
                const hasFlaggedComments = row.flaggedComments.some((fcmt) =>
                  fcmt.trim()
                );

                const renderIcons = () => {
                  if (!hasComments && !hasFlaggedComments) {
                    return (
                      <FaInfoCircle
                        style={{ color: 'blue', fontSize: '1rem' }}
                      />
                    );
                  }

                  if (hasComments && !hasFlaggedComments) {
                    return (
                      <div style={{ display: 'flex', gap: '4px' }}>
                        <FaInfoCircle
                          style={{ color: 'blue', fontSize: '1rem' }}
                        />
                        <FaFlag style={{ color: 'orange', fontSize: '1rem' }} />
                      </div>
                    );
                  }

                  if (!hasComments && hasFlaggedComments) {
                    return (
                      <div style={{ display: 'flex', gap: '4px' }}>
                        <FaInfoCircle
                          style={{ color: 'blue', fontSize: '1rem' }}
                        />
                        <FaFlag style={{ color: 'red', fontSize: '1rem' }} />
                      </div>
                    );
                  }

                  if (hasComments && hasFlaggedComments) {
                    return (
                      <div style={{ display: 'flex', gap: '4px' }}>
                        <FaInfoCircle
                          style={{ color: 'blue', fontSize: '1rem' }}
                        />
                        <FaFlag style={{ color: 'red', fontSize: '1rem' }} />
                        <FaFlag style={{ color: 'orange', fontSize: '1rem' }} />
                      </div>
                    );
                  }
                };

                return (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(row)}
                    className={`
                    ${
                      (row.logged > 40 && row.reportType === 'weekly') ||
                      (row.logged > 160 && row.reportType === 'monthly')
                        ? 'border-2 border-red-500'
                        : 'border border-transparent'
                    }
                    ${row.logged > 40 ? 'rounded-lg' : 'rounded-none'}
                    transition-all duration-300 ease-in-out
                  `}
                  >
                    <TableCell style={{ textAlign: 'center' }}>
                      {index + 1}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center' }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Link
                        className="text-blue-600 underline"
                        to={`/dashboard/user-analysis?userId=${row.userId}&startDate=${startDate}&endDate=${endDate}`}
                      >
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {row.type}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {row.loggedMinusFlagged}
                    </TableCell>
                    {/* <TableCell style={{ textAlign: 'center' }}>
                      {row.learning}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {row.flagged}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {row.loggedMinusFlagged}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {row.diff}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      {renderIcons()}
                    </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        style={{ borderRadius: '25px' }}
      >
        <DialogTitle
          style={{
            background: 'linear-gradient(90deg, #358FBF, #005f73)',
            color: '#fff',
            textAlign: 'center',
            padding: '20px 16px',
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
        >
          Details
          {startDate && endDate && (
            <span
              style={{
                fontSize: '1rem',
                fontWeight: 'normal',
                marginLeft: '10px',
                color: '#cce7f3',
              }}
            >
              ({startDate} - {endDate})
            </span>
          )}
        </DialogTitle>

        <DialogContent
          style={{
            backgroundColor: '#f9f9f9',
            padding: '24px',
          }}
        >
          {fetching ? ( // Show fetching state if true
            <Typography
              variant="body1"
              style={{
                textAlign: 'center',
                color: '#888',
                fontStyle: 'italic',
              }}
            >
              Fetching data...
            </Typography>
          ) : (
            selectedRow && (
              <div>
                <div
                  style={{
                    marginBottom: '16px',
                    padding: '16px',
                    background: '#e6f2f9',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 'bold',
                      color: '#005f73',
                    }}
                  >
                    <Link
                      to={`/dashboard/user-analysis?userId=${selectedRow.userId}&startDate=${startDate}&endDate=${endDate}`}
                    >
                      {selectedRow.name}
                    </Link>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 'bold',
                      color: '#333',
                    }}
                  >
                    {selectedRow.type}
                  </Typography>
                </div>

                {/* Comments Section */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '12px',
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 'bold',
                      color: '#005f73',
                    }}
                  >
                    Comments
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontStyle: 'italic',
                      color: '#888',
                    }}
                  >
                    * Black: general, Red: flagged
                  </Typography>
                </div>

                {(selectedRow.comment &&
                  selectedRow.comment.filter((cmt) => cmt.trim()).length > 0) ||
                (selectedRow.flaggedComments &&
                  selectedRow.flaggedComments.filter((fcmt) => fcmt.trim())
                    .length > 0) ? (
                  <div>
                    {selectedRow.comment &&
                      selectedRow.comment.filter((cmt) => cmt.trim()).length >
                        0 && (
                        <ul
                          style={{
                            listStyleType: 'disc',
                            paddingLeft: '16px',
                          }}
                        >
                          {selectedRow.comment
                            .filter((cmt) => cmt.trim())
                            .map((cmt, i) => (
                              <li
                                key={i}
                                style={{
                                  marginBottom: '8px',
                                  color: '#333',
                                }}
                              >
                                <Typography variant="body2">{cmt}</Typography>
                              </li>
                            ))}
                        </ul>
                      )}
                    {selectedRow.flaggedComments &&
                      selectedRow.flaggedComments.filter((fcmt) => fcmt.trim())
                        .length > 0 && (
                        <ul
                          style={{
                            listStyleType: 'disc',
                            paddingLeft: '16px',
                          }}
                        >
                          {selectedRow.flaggedComments
                            .filter((fcmt) => fcmt.trim())
                            .map((fcmt, i) => (
                              <li
                                key={i}
                                style={{
                                  marginBottom: '8px',
                                  color: 'red',
                                }}
                              >
                                <Typography variant="body2">{fcmt}</Typography>
                              </li>
                            ))}
                        </ul>
                      )}
                  </div>
                ) : (
                  <Typography
                    variant="body2"
                    style={{ color: '#888', fontStyle: 'italic' }}
                  >
                    No comments available
                  </Typography>
                )}

                {/* Project Details Section */}
                <Typography
                  variant="h6"
                  style={{
                    marginBottom: '12px',
                    fontWeight: 'bold',
                    color: '#005f73',
                    paddingTop: '10px',
                  }}
                >
                  Project Details
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '16px' }}>
                  {projectDetails.length > 0 ? (
                    projectDetails.map(([projectName, hours], i) => (
                      <li
                        key={i}
                        style={{ marginBottom: '8px', color: '#333' }}
                      >
                        <Typography variant="body2">
                          {projectName}: {hours} hours
                        </Typography>
                      </li>
                    ))
                  ) : (
                    <Typography variant="body2" style={{ color: '#888' }}>
                      No project details available.
                    </Typography>
                  )}
                </ul>
              </div>
            )
          )}
        </DialogContent>
        <DialogActions
          style={{
            background: 'linear-gradient(90deg, #358FBF, #005f73)',
            padding: '12px',
          }}
        >
          <Button
            onClick={handleDialogClose}
            style={{
              color: '#fff',
              backgroundColor: '#005f73',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );

  const calculateMinMaxDomain = (chartData) => {
    const diffs = chartData.map((item) => item.diff);
    const maxDiff = Math.max(...diffs, 0); // Ensure maxDiff is at least 0
    const minDiff = Math.min(...diffs, 0); // Ensure minDiff is at most 0
    return [minDiff, maxDiff]; // Base starts at 0 if positive only
  };

  const ShowFSLabsTable = React.memo(({ fsLabsData: data }) => {
    return (
      <div>
        <TableContainer style={{ marginTop: '60px' }}>
          <Paper>
            <h1 className="flex justify-center items-center text-5xl font-bold pb-4 bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
              Users Working on FS Lab Projects*
            </h1>

            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#f1f1f1' }}>
                  <TableCell
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: '#000',
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: '#000',
                    }}
                  >
                    Projects & Hours
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length > 0 ? (
                  data.map((user, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                      }}
                    >
                      <TableCell
                        style={{
                          textAlign: 'center',
                          color: '#000',
                        }}
                      >
                        {user.user}
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#000',
                        }}
                      >
                        <ul>
                          {user.projects.map((project, i) => (
                            <li key={i}>
                              {project.project}:{' '}
                              {project.totalHours?.toFixed(1)} hours
                            </li>
                          ))}
                        </ul>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      style={{
                        textAlign: 'center',
                        fontWeight: '500',
                        color: '#888',
                      }}
                    >
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </TableContainer>
      </div>
    );
  });

  const renderBarChart = (title, chartData, color) => {
    const [minDomain, maxDomain] = calculateMinMaxDomain(chartData);

    return (
      <>
        <h1 className="flex justify-center items-center text-5xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
          {title}
        </h1>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[minDomain, maxDomain]} tickCount={8} />
            <Tooltip />
            <Bar dataKey="diff" fill={color} />
          </BarChart>
        </ResponsiveContainer>
      </>
    );
  };
  const peopleWithLeave = data
    .filter((d) => d.leave > 0) // Filter users with leave hours > 0
    .sort((a, b) => b.leave - a.leave); // Filter users with leave hours > 0
  const topOverutilized = data
    .filter((d) => d.diff > 0 && d.type.toLowerCase() === 'fulltime') // Exclude freelancers
    .sort((a, b) => b.diff - a.diff)
    .slice(0, 5);

  const topUnderutilized = data
    .filter((d) => d.diff < 0 && d.type.toLowerCase() === 'fulltime') // Exclude freelancers
    .sort((a, b) => a.diff - b.diff)
    .slice(0, 5);

  const peopleWithLearning = data
    .filter((d) => d.learning > 0)
    .sort((a, b) => b.learning - a.learning);
  // console.log('Learning Data', peopleWithLearning);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const weeks =
    reportType === 'weekly'
      ? getWeekRanges(currentYear)
      : getMonthRanges(currentYear);
  const [selectedWeek, setSelectedWeek] = useState(
    getCurrentWeekNumber() - 1 === 0 ? 1 : getCurrentWeekNumber() - 1
  );

  const handleWeekChange = (e) => {
    setSelectedWeek(e.target.value);
  };
  useEffect(() => {
    if (reportType === 'monthly') {
      const startDate = weeks.find((w) => w.name === selectedWeek)?.start;
      const endDate = weeks.find((w) => w.name === selectedWeek)?.end;
      setStartDate(startDate);
      setEndDate(endDate);
    } else {
      const startDate = weeks.find(
        (w) => w.week === parseInt(selectedWeek)
      )?.start;
      const endDate = weeks.find((w) => w.week === parseInt(selectedWeek))?.end;
      setStartDate(startDate);
      setEndDate(endDate);
    }
  }, [selectedWeek]);
  return (
    <StyledContainer>
      <style>
        {`
          @media print {
            body {
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important;
            }
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: center;
            }
            .row-positive {
              background-color: #90ee90 !important;
            }
            .row-negative {
              background-color: #ff6347 !important;
            }
            .row-neutral {
              background-color: #ffffff !important;
            }
          }
          .row-positive {
            background-color: rgba(144, 238, 144, 0.6);
          }
          .row-negative {
            background-color: rgba(255, 99, 71, 0.6);
          }
          .row-neutral {
            background-color: #ffffff;
          }
        `}
      </style>

      <Title>
        <div className="flex justify-center items-center relative w-full">
          <h1 className="sticky top-0  text-3xl font-extrabold text-gray-900 dark:text-black md:text-5xl lg:text-6xl">
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
              Utilization
            </span>{' '}
            Reports
          </h1>
        </div>
        <p
          className="cursor-pointer text-sm"
          onClick={() =>
            reportType === 'weekly'
              ? setReportType('monthly')
              : setReportType('weekly')
          }
        >
          {reportType === 'monthly' ? (
            <p>Click to view monthly report</p>
          ) : (
            <p>Click to view weekly report</p>
          )}
        </p>
        <div>
          <div className="space-y-4 w-full flex flex-col items-center">
            {reportType === 'weekly' ? (
              <label
                htmlFor="week-dropdown"
                className="block text-2xl font-extrabold text-black  "
              >
                Select Week Range
              </label>
            ) : (
              <label
                htmlFor="week-dropdown"
                className="block text-2xl font-extrabold text-black  "
              >
                Select Monthy Range
              </label>
            )}
            <div className="flex items-center  gap-10 w-full">
              {/* Previous Year Button */}
              {currentYear > 2024 && (
                <button
                  onClick={() => setCurrentYear((year) => year - 1)}
                  className="px-4 py-2 text-xs font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Previous Year
                </button>
              )}

              {/* Label */}

              {reportType === 'weekly' && (
                <select
                  id="week-dropdown"
                  value={selectedWeek}
                  onChange={handleWeekChange}
                  className="block w-3/4 px-4 py-2 text-sm border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="" disabled>
                    -- Select a Week --
                  </option>
                  {weeks.map((week) => (
                    <option key={week.week} value={week.week}>
                      Week {week.week}: ({week?.start} - {week?.end})
                    </option>
                  ))}
                </select>
              )}

              {reportType === 'monthly' && (
                <select
                  id="week-dropdown"
                  value={selectedWeek}
                  onChange={handleWeekChange}
                  className="block w-3/4 px-4 py-2 text-sm border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="" disabled>
                    -- Select a Week --
                  </option>
                  {weeks.map((month) => (
                    <option key={month.week} value={month.name}>
                      {month.name} {month.week}: ({month?.start} - {month?.end})
                    </option>
                  ))}
                </select>
              )}
              {/* Next Year Button (conditionally hidden) */}
              {currentYear < new Date().getFullYear() && (
                <button
                  onClick={() => setCurrentYear((year) => year + 1)}
                  className="px-4 py-2 text-xs font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Next Year
                </button>
              )}
            </div>

            {/* Dropdown */}
          </div>
        </div>
        {startDate && endDate && (
          <p className="text-xl font-bold my-2">
            (From {startDate} to {endDate})
          </p>
        )}
      </Title>
      <div style={{ marginTop: '40px' }}>
        {topOverutilized.length > 0 &&
          renderBarChart(
            'Top 5 Overutilized Users',
            topOverutilized,
            '#82ca9d'
          )}
      </div>
      <div style={{ marginTop: '40px' }}>
        {topUnderutilized.length > 0 &&
          renderBarChart(
            'Top 5 Underutilized Users',
            topUnderutilized,
            '#fa5555'
          )}
      </div>
      <div>
        <div>
          <TableContainer style={{ marginTop: '60px' }}>
            <Paper>
              <h1 className="flex justify-center items-center text-5xl font-bold pb-4 bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
                Users Working on Multiple Projects*
              </h1>
              <Typography
                variant="body2"
                style={{
                  textAlign: 'center',
                  marginBottom: '10px',
                  color: '#888',
                  fontStyle: 'italic',
                }}
              >
                *The hours do not include GPTW, leave, or holidays.
              </Typography>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#f1f1f1' }}>
                    <TableCell
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#000',
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#000',
                      }}
                    >
                      Projects & Hours
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersWithMultipleProjects.length > 0 ? (
                    usersWithMultipleProjects.map((user, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                        }}
                      >
                        <TableCell
                          style={{
                            textAlign: 'center',
                            color: '#000',
                          }}
                        >
                          {user.name}
                        </TableCell>
                        <TableCell
                          style={{
                            color: '#000',
                          }}
                        >
                          <ul>
                            {Object.entries(user.projects).map(
                              ([projectName, hours], i) => (
                                <li key={i}>
                                  {projectName}: {hours?.toFixed(1)} hours
                                </li>
                              )
                            )}
                          </ul>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        style={{
                          textAlign: 'center',
                          fontWeight: '500',
                          color: '#888',
                        }}
                      >
                        No users found with multiple projects.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </TableContainer>
        </div>
      </div>

      <TableContainer style={{ marginTop: '60px' }}>
        <Paper>
          <h1 className="flex justify-center items-center text-5xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
            Users with Comments*
          </h1>
          <Typography
            variant="body2"
            style={{
              textAlign: 'center',
              marginBottom: '10px',
              color: '#888',
              fontStyle: 'italic',
            }}
          >
            * <span style={{ color: 'black' }}>Black</span> comments are
            general. <span style={{ color: 'red' }}>Red</span> comments are
            flagged.
          </Typography>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f1f1f1' }}>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#000',
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#000',
                  }}
                >
                  Comments
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {peopleWithComments.filter(
                (user) =>
                  user.comment.some((cmt) => cmt.trim() !== '') ||
                  user.flaggedComments.length > 0
              ).length > 0 ? (
                peopleWithComments
                  .filter(
                    (user) =>
                      user.comment.some((cmt) => cmt.trim() !== '') ||
                      user.flaggedComments.length > 0
                  )
                  .map((user, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ textAlign: 'center', color: '#000' }}>
                        {user.name}
                      </TableCell>
                      <TableCell>
                        <ul>
                          {/* Display General Comments */}
                          {user.comment
                            .filter((cmt) => cmt.trim() !== '')
                            .map((comment, i) => (
                              <li key={`comment-${i}`}>
                                <Typography
                                  variant="body2"
                                  style={{ color: 'black' }}
                                >
                                  {comment}
                                </Typography>
                              </li>
                            ))}
                          {/* Display Flagged Comments */}
                          {user.flaggedComments.map((flagged, i) => (
                            <li key={`flagged-${i}`}>
                              <Typography
                                variant="body2"
                                style={{ color: 'red' }}
                              >
                                {flagged}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    style={{
                      textAlign: 'center',
                      fontWeight: '500',
                      color: '#888',
                    }}
                  >
                    No comments available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </TableContainer>
      {/* {Learning } */}
      <TableContainer style={{ marginTop: '60px' }}>
        <Paper>
          <h1 className="flex justify-center items-center text-5xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
            Users with Learning*
          </h1>

          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f1f1f1' }}>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#000',
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#000',
                  }}
                >
                  Time (Hrs)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {peopleWithLearning.length > 0 ? (
                peopleWithLearning.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ textAlign: 'center', color: '#000' }}>
                      {user.name}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', color: '#000' }}>
                      <ul>{user.learning}</ul>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    style={{
                      textAlign: 'center',
                      fontWeight: '500',
                      color: '#888',
                    }}
                  >
                    No comments available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </TableContainer>

      {/* const peopleWithLeave = data.filter((d) => d.leave > 0); // Filter users with leave hours > 0 */}

      {peopleWithLeave.length > 0 && (
        <TableContainer style={{ marginTop: '60px' }}>
          <Paper
            style={{
              borderRadius: '12px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
            }}
          >
            <h1 className="flex justify-center items-center text-5xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
              Users who took Leave
            </h1>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#f1f1f1' }}>
                  <TableCell
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: '#000',
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: '#000',
                    }}
                  >
                    Leave Hours
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {peopleWithLeave.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff', // Alternate row colors
                      color: '#000', // Black text color
                      transition: 'all 0.3s',
                    }}
                  >
                    <TableCell
                      style={{
                        textAlign: 'center',
                        fontWeight: '500',
                        color: '#000',
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        fontWeight: '500',
                        color: '#000',
                      }}
                    >
                      {row.leave}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </TableContainer>
      )}

      <RenderPartTimeFreelancer />
      <ShowFSLabsTable fsLabsData={fsLabsData} />
      {renderFullTable()}
      {isLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-800/80 flex justify-center z-50 items-center">
          <RiLoader4Fill className="text-7xl text-white animate-spin" />
        </div>
      )}
    </StyledContainer>
  );
};

export default DetailedUtilization;

import React from "react";

const UsersWithLearningTable = ({ data }) => {
  return (
    <div>
      <h2>Users with Learning Hours</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Learning Hours</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user, index) => (
            <tr key={index}>
              <td>{user.name}</td>
              <td>{user.learning} hours</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(UsersWithLearningTable);

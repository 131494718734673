import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Dialog, DialogTitle, DialogContent, Typography,Button,DialogActions } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const AttendanceCalendar = ({ userId, startDate, endDate }) => {
    const [punchDates, setPunchDates] = useState([]);
    const [selectedDatePunches, setSelectedDatePunches] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchPunchDates = async () => {
            if (!userId) return;

            try {
                const response = await axios.get(`${BASE_URL}/user-range`, {
                    params: {
                        userId,
                        startDateTime: startDate,
                        endDateTime: endDate,
                    },
                });

                setPunchDates(response.data);
            } catch (error) {
                console.error('Error fetching punch dates:', error);
            }
        };

        fetchPunchDates();
    }, [userId, startDate, endDate]);

    const handleDateClick = (date) => {
        const isSameDay = (punchDate, selectedDate) => {
            const punch = dayjs.utc(punchDate);
            return punch.year() === selectedDate.getFullYear() &&
                   punch.month() === selectedDate.getMonth() &&
                   punch.date() === selectedDate.getDate();
        };

        const punchesOnDate = punchDates.filter((punch) => isSameDay(punch.PunchTime, date));

        if (punchesOnDate.length > 0) {
            const extractTime = (punch) => dayjs.utc(punch.PunchTime).format('HH:mm:ss');

            const sortedPunches = punchesOnDate.sort((a, b) => {
                const timeA = extractTime(a);
                const timeB = extractTime(b);
                return timeA.localeCompare(timeB);
            });

            setSelectedDatePunches({
                firstPunch: extractTime(sortedPunches[0]),
                lastPunch: extractTime(sortedPunches[sortedPunches.length - 1]),
                allPunches: sortedPunches.map((punch) => extractTime(punch)),
            });
            setDialogOpen(true);
        } else {
            setSelectedDatePunches(null);
            setDialogOpen(false);
        }
    };

    const groupPunchDatesByDate = () => {
        return punchDates.reduce((acc, punch) => {
            const dateKey = dayjs.utc(punch.PunchTime).format('YYYY-MM-DD');
            acc[dateKey] = (acc[dateKey] || 0) + 1; // Count punches per date
            return acc;
        }, {});
    };

    const punchCountByDate = groupPunchDatesByDate();

    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const isWeekend = date.getDay() === 0 || date.getDay() === 6;
            if (isWeekend) return 'no-punch';

            const dateString = dayjs(date).format('YYYY-MM-DD');
            const hasPunch = punchCountByDate[dateString] > 0;
            return hasPunch ? 'has-punch' : 'no-punch';
        }
    };

    return (
        <div className="calendar-container">
            <Calendar onClickDay={handleDateClick} tileClassName={tileClassName} />

            <Dialog
  open={dialogOpen}
  onClose={() => setDialogOpen(false)}
  PaperProps={{
    style: {
      borderRadius: '10px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      width: '470px', // Set the desired width
    },
  }}
>
  <DialogTitle sx={{ padding: 0 }}> {/* Remove default padding */}
    <div className="bg-gradient-to-r from-[#3a97d2] via-[#2b7d99] to-[#1f5f77] text-white p-4 rounded-t-lg text-center">
      Punch Details
    </div>
  </DialogTitle>

  <DialogContent className="bg-white rounded-b-lg p-4 mt-4"> {/* Add padding here */}
    {selectedDatePunches ? (
      <div className="space-y-2">
        <h3 className="text-lg font-semibold text-gray-700">
          First Check-In: <span className="text-blue-600">{selectedDatePunches.firstPunch}</span>
        </h3>
        <h3 className="text-lg font-semibold text-gray-700">
          Last Check-Out: <span className="text-blue-600">{selectedDatePunches.lastPunch}</span>
        </h3>

        <h3 className="text-lg font-semibold text-gray-700">All Punches:</h3>
        <ul className="space-y-2 bg-white p-2 rounded-lg max-h-40 overflow-y-auto">
          {selectedDatePunches.allPunches && selectedDatePunches.allPunches.length > 0 ? (
            selectedDatePunches.allPunches.map((punch, index) => (
              <li key={index} className="bg-blue-100 text-blue-700 rounded-lg py-2 px-4 text-sm shadow-sm">
                {punch}
              </li>
            ))
          ) : (
            <li className="text-gray-500">No punches recorded</li>
          )}
        </ul>
      </div>
    ) : (
      <Typography align="center" variant="body1">
        No punches for the selected date
      </Typography>
    )}
  </DialogContent>

  <DialogActions>
    <Button
      onClick={() => setDialogOpen(false)}
      className="text-white hover:to-blue-500 transition-all duration-200 ease-in-out px-4 py-2 rounded-lg"
    >
      Close
    </Button>
  </DialogActions>
</Dialog>


<style jsx>{`
    .calendar-container {
        max-width: 100%;
        margin: auto;
        background-color: white;
        border-radius: 16px;
        padding: 40px;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    }

    .react-calendar {
        width: 100%;
        max-width: 1000px;
        font-family: 'Montserrat', sans-serif;
        border: none; /* Remove the thin black border */
    }

    .react-calendar__navigation__label {
        font-size: 2rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        color: #46C8C8;
        padding: 10px;
    }

    .react-calendar__tile {
        border-radius: 10px;
        font-weight: 600;
        transition: background-color 0.3s ease, transform 0.3s ease;
        font-size: 1.2rem;
        padding: 12px;
    }

    .react-calendar__tile--active {
        background-color: #0066ff;
        color: white;
        border-radius: 10px;
    }

    .has-punch {
        background-color: #46C8C8 !important;
        color: white;
    }

    .no-punch {
        background-color: white !important;
        color: #999;
    }

    /* Updated the current date tile color to green */
    .react-calendar__tile--now {
        background-color: #4CAF50 !important; /* Green color */
        color: white;
        border-radius: 10px;
        font-weight: bold;
    }

    .react-calendar__tile:hover {
        background-color: #e3f2fd;
        transform: scale(1.1);
    }
`}</style>
        </div>
    );
};

export default AttendanceCalendar;

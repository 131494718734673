import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import BarChartComponent from "./BarChartComponent";
import UsersWithCommentsTable from "./UsersWithComments";
import UsersWithLearningTable from "./UsersWithLearning";
import UsersWithLeaveTable from "./UsersWithLeave";
import FSUtilizationTable from "./FSUtilisationTable";
import { getCurrentWeekNumber, getMonthRanges, getWeekRanges } from "../Utils";
import { StyledContainer, Title } from "../Utilization";

const DetailedUtilizationV2 = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [reportType, setReportType] = useState("weekly");
  const [selectedWeek, setSelectedWeek] = useState(
    getCurrentWeekNumber() - 1 === 0 ? 1 : getCurrentWeekNumber() - 1
  );

  const weeks = useMemo(
    () =>
      reportType === "weekly"
        ? getWeekRanges(currentYear)
        : getMonthRanges(currentYear),
    [reportType, currentYear]
  );

  useEffect(() => {
    if (selectedWeek) {
      const selectedRange = weeks.find((w) =>
        reportType === "weekly"
          ? w.week === parseInt(selectedWeek)
          : w.name === selectedWeek
      );
      if (selectedRange) {
        setStartDate(selectedRange.start);
        setEndDate(selectedRange.end);
      }
    }
  }, [selectedWeek, weeks, reportType]);

  useEffect(() => {
    const fetchData = async () => {
      if (!startDate || !endDate) return;

      try {
        const response = await axios.get(
          `https://timesheet-be.fleetstudio.com/api/user/reports/filter/${startDate}/${endDate}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const filteredData = useMemo(() => {
    return {
      overutilized: data
        .filter((d) => d.diff > 0 && d.type.toLowerCase() === "fulltime")
        .sort((a, b) => b.diff - a.diff)
        .slice(0, 5),
      underutilized: data
        .filter((d) => d.diff < 0 && d.type.toLowerCase() === "fulltime")
        .sort((a, b) => a.diff - b.diff)
        .slice(0, 5),
      //   withComments: data.filter(
      //     (user) =>
      //       user.comment.some((cmt) => cmt.trim() !== "") ||
      //       user.flaggedComments.length > 0
      //   ),
      withLearning: data.filter((d) => d.learning > 0),
      withLeave: data.filter((d) => d.leave > 0),
    };
  }, [data]);

  return (
    <StyledContainer>
      <Title>Utilization Reports</Title>
      <div>
        <BarChartComponent
          title="Top 5 Overutilized Users"
          data={filteredData?.overutilized}
          color="#82ca9d"
        />
        <BarChartComponent
          title="Top 5 Underutilized Users"
          data={filteredData?.underutilized}
          color="#fa5555"
        />
      </div>
      {/* <UsersWithCommentsTable data={filteredData?.withComments} /> */}
      <UsersWithLearningTable data={filteredData?.withLearning} />
      <UsersWithLeaveTable data={filteredData?.withLeave} />
      <FSUtilizationTable startDate={startDate} endDate={endDate} />
    </StyledContainer>
  );
};

export default DetailedUtilizationV2;

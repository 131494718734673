import React, { useState, useEffect } from "react";
import { FormControlLabel } from '@mui/material';
import { saveAs } from 'file-saver';
import '../components/TaskViewTable.css'; 
import { getMediumNameForProjects,getMediumNameForTask } from "../utensils/simplifier";
import getPreviousWeek from "../utensils/prevWeek";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';


import {
  Button,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Paper,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  fetchProjects, // Renamed from fetchActiveUsers
  fetchProjectData,
} from "../api/api1"; // Ensure this function fetches projects correctly
import { getShortNameForProjects, getShortNameForTask } from "../utensils/simplifier"; // Import the simplifier functions


const StyledContainer = styled(Container)({
  backgroundColor: "#FFFFFF",
  padding: "30px",
  borderRadius: "16px",
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
  maxWidth: "100%",
});
const Title = styled("h1")({
  textAlign: "center",
  color: "#0d47a1",
  marginBottom: "30px",
  fontSize: "2.5rem",
  fontFamily: "Roboto, sans-serif",
});
const UploadSection = styled("div")({
  marginBottom: "30px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
});
const ChartContainer = styled(Paper)({
  marginTop: "20px",
  padding: "20px",
  borderRadius: "12px",
  backgroundColor: "#f8f9fa",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
const Navbar = styled('div')(({ scrollPosition }) => ({
  position: scrollPosition > 0 ? 'absolute' : 'relative',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: '#FFFFFF',
  padding: '20px',
  transition: 'all 0.3s ease',
  opacity: scrollPosition > 0 ? 1 : 0,
  zIndex: 10,
  boxShadow: scrollPosition > 0 ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
}));

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#A6D854"];


const ProjectAnalysis = () => {
  const { start, end } = getPreviousWeek();
  const [timesheetData, setTimesheetData] = useState({});
  const [selectedProject, setSelectedProject] = useState(null); // Renamed from selectedUser
  const [activeProjects, setActiveProjects] = useState([]); // Renamed from activeUsers
  const [selectedView, setSelectedView] = useState('project'); // state to track the current view
  const [selectedOption, setSelectedOption] = useState('week');
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [isDateReady, setIsDateReady] = useState(false);
  const [loading, setLoading] = useState(false); // To handle loading state 
  const [error, setError] = useState(null); // To handle errors

  useEffect(() => {
    const fetchProjectsData = async () => {
      try {
        const projects = await fetchProjects(); // Ensure this fetches projects correctly
        setActiveProjects(projects);
      } catch (err) {
        console.error("Error fetching projects:", err);
        setError("Failed to fetch projects.");
      }
    };
    fetchProjectsData();
  }, []);
 

  useEffect(() => {
    let newStartDate = startDate; // Use a local variable for calculation
    let newEndDate = startDate; // Initialize newEndDate based on startDate

    // Adjust date range based on selectedOption
    if (selectedOption === 'day') {
      // For a single day, add one day to the endDate
      newEndDate = new Date(newStartDate);
      newEndDate.setDate(newEndDate.getDate() + 1);
      
    } else if (selectedOption === 'week') {
      // For a week, calculate the start and end of the week
      newStartDate = new Date(startDate);
      newStartDate.setDate(newStartDate.getDate() - newStartDate.getDay()); // Start of the week (Sunday)
      newEndDate = new Date(newStartDate);
      newEndDate.setDate(newEndDate.getDate() + 6); // End of the week (Saturday)
    } else if (selectedOption === 'month') {
      // For a month, calculate the start and end of the month
      newStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1); // Start of the month
      newEndDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0); // End of the month
    }

    // Only update the state if necessary to avoid re-triggering the effect
    if (newStartDate.getTime() !== startDate.getTime()) {
      setStartDate(newStartDate); // Update startDate only if it's different
    }
    if (newEndDate.getTime() !== endDate?.getTime()) {
      setEndDate(newEndDate); // Update endDate only if it's different
    }
    setIsDateReady(true);
  }, [selectedProject,selectedOption, startDate, endDate]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    // Reset the date range when the option changes
    setStartDate(start);
    setEndDate(end);
  };
  

  const fetchData = async () => {
    if (selectedProject && startDate && endDate) {
      setLoading(true);
      setError(null);
      try {
        // Format the dates as required by your API
        const min_date = startDate.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
        const max_date = endDate.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'

        // Use the formatted dates in your API call
        const data = await fetchProjectData(selectedProject, min_date, max_date);

        // Update the state with the fetched data
        setTimesheetData(data);
      } catch (err) {
        console.error("Error fetching timesheet data:", err);
        setError("Failed to fetch timesheet data.");
      } finally {
        setLoading(false);
        setIsDateReady(false);
      }
    }
  };

  const handleProjectChange = (event) => { // Renamed from handleUserChange
    setSelectedProject(event.target.value);
  };

  const handleDateChange = (date, isStartDate) => {
    if (isStartDate) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const adjustDates = (direction) => {
    const newStartDate = new Date(startDate); // Clone startDate
    const newEndDate = new Date(startDate); // Clone for endDate adjustment
  
    if (selectedOption === "day") {
      // Move by 1 day
      newStartDate.setDate(newStartDate.getDate() + (direction === "next" ? 1 : -1));
      newEndDate.setDate(newStartDate.getDate()); // Same as startDate
    } else if (selectedOption === "week") {
      // Move by 7 days
      newStartDate.setDate(newStartDate.getDate() + (direction === "next" ? 7 : -7));
      newEndDate.setDate(newStartDate.getDate() + 6); // Set to the end of the week
    } else if (selectedOption === "month") {
      // Move by 1 month
      newStartDate.setMonth(newStartDate.getMonth() + (direction === "next" ? 1 : -1));
      newStartDate.setDate(1); // Start of the new month
      newEndDate.setFullYear(newStartDate.getFullYear(), newStartDate.getMonth() + 1, 0); // End of the new month
    }
  
    setStartDate(newStartDate); // Update startDate
    setEndDate(newEndDate); // Update endDate
  };
  
  
  const handleExportCSV = (isMonthSelected) => {
    if (!selectedProject || !Object.keys(timesheetData).length) return;
  
    const project = timesheetData[selectedProject];
    if (!project) return;
  
    // Initialize CSV content
    let csvContent = `Project Name: ${project.projectName}\n`;
  
    // Extract tasks and users
    const users = project.users;
    const tasksMap = {};
    const userNames = [];
  
    // Collect task names and initialize task map
    Object.values(users).forEach(user => {
      Object.values(user.hours).forEach(task => {
        tasksMap[task.name] = true;
      });
    });
  
    const taskNames = Object.keys(tasksMap);
  
    // Add headers to the CSV
    csvContent += `User Name, ${taskNames.join(", ")}\n`;
  
    // Loop through users to populate rows
    Object.values(users).forEach(user => {
      const row = [user.name];
      taskNames.forEach(taskName => {
        // Find the task in the user's hours
        const task = Object.values(user.hours).find(t => t.name === taskName);
        row.push(task ? task.hour : 0);
      });
      csvContent += row.join(", ") + "\n";
    });
  
    // Create Blob with proper CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
  
    // Format the start and end dates as 'Month Day, Year'
    const formatDate = (date) => {
      return date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).replace(/, /g, '_'); // Replace commas and spaces with underscores
    };
  
    const startFormatted = formatDate(startDate);
    const endFormatted = formatDate(endDate);
  
    // Generate the filename based on whether it's month data or custom date range
    const fileName = `${project.projectName}_data_${startFormatted}_to_${endFormatted}.csv`;
  
    // Save the file
    saveAs(blob, fileName);
  };
  

  // const taskNameMapping = {
  //   "Development Task A": "Dev A",
  //   "Development Task B": "Dev B",
  //   "QA Task C": "QA C",
  //   // Add other task mappings
  // };
  
  // const getShortNameForTask = (taskName) => taskNameMapping[taskName] || taskName;
  
  // Function to get the short name for users
const getShortNameForUser = (fullName) => {
  // Implement logic to return a shorter version of the user's name
  // For example, use only the first letter of the first name and the full last name
  // Adjust this logic as per your requirement
  const names = fullName.split(" ");
  return names[0];
};
const calculateTotalHours = (data) => {
  let totalHours = 0;

  Object.keys(data).forEach((projectId) => {
    const project = data[projectId];
    Object.keys(project.users).forEach((userId) => {
      const user = project.users[userId];
      Object.keys(user.hours).forEach((taskId) => {
        totalHours += parseFloat(user.hours[taskId].hour) || 0;
      });
    });
  });

  return totalHours;
};

const totalHours = calculateTotalHours(timesheetData);

// Example usage of the function in processData
const processData = (data) => {
    if (!selectedProject || !data || !Object.keys(data).length)
      return { taskDistribution: [], projectDistribution: [], userDistribution: [] };
  
    const taskDistribution = [];
    const projectDistribution = [];
    const userDistribution = [];
  
    const project = data[selectedProject];
    if (!project) return { taskDistribution: [], projectDistribution: [], userDistribution: [] };
  
    const users = project.users;
  
    Object.values(users).forEach((user) => {
      const userTasks = user.hours;
  
      // Processing task distribution
      Object.values(userTasks).forEach((task) => {
        const shortTaskName = getShortNameForTask(task.name);
        const existingTask = taskDistribution.find((t) => t.name === task.name); // Using long name for chart
        if (existingTask) {
          existingTask.value += task.hour;
        } else {
          taskDistribution.push({ name: task.name, value: task.hour }); // Long name for chart
        }
      });
  
      // Processing project distribution
      const totalProjectHours = Object.values(userTasks).reduce(
        (sum, task) => sum + task.hour,
        0
      );
      const shortProjectName = getShortNameForProjects(project.projectName);
      const existingProject = projectDistribution.find((p) => p.name === project.projectName); // Using long name for chart
      if (existingProject) {
        existingProject.value += totalProjectHours;
      } else {
        projectDistribution.push({
          name: project.projectName, // Long name for chart
          value: totalProjectHours,
        });
      }
  
      // Processing user distribution with full name
      const totalUserHours = Object.values(userTasks).reduce(
        (sum, task) => sum + task.hour,
        0
      );
      const fullName = user.name; // Full name for chart
      const shortName = getShortNameForUser(user.name); // Short name for legend
      const existingUser = userDistribution.find((u) => u.name === fullName); // Using full name for chart
      if (existingUser) {
        existingUser.value += totalUserHours;
      } else {
        userDistribution.push({ name: fullName, value: totalUserHours }); // Full name for chart
      }
    });
  
    return { taskDistribution, projectDistribution, userDistribution };
  };

  const renderCharts = () => {
    if (!selectedProject || !Object.keys(timesheetData).length) return null;
  
    // Extract taskDistribution, projectDistribution, and userDistribution from processData
    const { taskDistribution, projectDistribution, userDistribution } = processData(timesheetData);
  
    if ((!taskDistribution || taskDistribution.length === 0) || (!userDistribution || userDistribution.length === 0)) {
      return (
        <div className="flex justify-center items-center h-64">
          <h2 className="text-xl font-semibold text-gray-500">No timesheet data found.</h2>
        </div>
      );
    }
  
    if (taskDistribution.length === 0 && projectDistribution.length === 0 && userDistribution.length === 0) {
      return <p>No data available for the selected project and date range.</p>;
    }
  
    return (
      <div className="flex flex-col md:flex-row gap-4">
        {/* Task Distribution Chart */}
        <div className="w-full md:w-1/2">
          <h1 className="flex justify-center items-center text-4xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text">
            Task Distribution
          </h1>
  
          <ChartContainer>
            <ResponsiveContainer width="100%" height={500}>
              <PieChart>
                <Pie
                  data={taskDistribution}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={200}
                  innerRadius={100}
                  paddingAngle={5}
                >
                  {taskDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                {/* Use medium names in Tooltip */}
                <Tooltip formatter={(value, name) => [`${value} hours`, getMediumNameForTask(name)]} />
                <Legend
                  verticalAlign="bottom"
                  wrapperStyle={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    maxHeight: "100px",  // Limit height of the legend
                    overflowY: "scroll", // Enable vertical scrolling
                    paddingTop: "10px",  // Add some spacing from the chart
                  }}
                  iconType="circle"
                  formatter={(value) => getShortNameForTask(value)} // Use short name in legend
                />
              </PieChart>
            </ResponsiveContainer>
          </ChartContainer>
        </div>
  
        {/* User Distribution Chart */}
        <div className="w-full md:w-1/2">
          <h1 className="flex justify-center items-center text-4xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text">
            User Distribution
          </h1>
  
          <ChartContainer>
            <ResponsiveContainer width="100%" height={500}>
              <PieChart>
                <Pie
                  data={userDistribution}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={200}
                  innerRadius={100}
                  paddingAngle={5}
                >
                  {userDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                {/* Use medium names in Tooltip */}
                <Tooltip formatter={(value, name) => [`${value} hours`, (name)]} />
                <Legend
                  verticalAlign="bottom"
                  wrapperStyle={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    maxHeight: "100px",  // Limit height of the legend
                    overflowY: "scroll", // Enable vertical scrolling
                    paddingTop: "10px",  // Add some spacing from the chart
                  }}
                  iconType="circle"
                  formatter={(value) => getShortNameForUser(value)} // Use short name in legend
                />
              </PieChart>
            </ResponsiveContainer>
          </ChartContainer>
        </div>
      </div>
    );
  };
  
  
  useEffect(() => {
    if (selectedProject && isDateReady) {
      fetchData();
    }
  }, [selectedProject, startDate, endDate, isDateReady]);
  const { taskDistribution, projectDistribution, userDistribution } = processData(timesheetData);
  return (
    <StyledContainer>
    <div className="relative">
      <Title>
        <h1 className="sticky top-0 mb-4 text-3xl font-extrabold text-gray-900 dark:text-black md:text-5xl lg:text-6xl">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
            Project
          </span> Analysis
        </h1>
        <div style={{ borderBottom: '2px solid #0d47a1', marginBottom: '20px' }}></div>
      </Title>

      {/* Sticky Nav */}
      <div className="sticky top-0 bg-white z-10 flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 mb-6 p-4" style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
        {/* Date Range Dropdown */}
        <FormControl style={{ width: '100%', maxWidth: '150px' }}>
          <InputLabel id="date-range-label">Select Range</InputLabel>
          <Select
            labelId="date-range-label"
            id="date-range"
            value={selectedOption}
            onChange={handleOptionChange}
            label="Select Range"
          >
            {/* <MenuItem value="day">Day</MenuItem> */}
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </Select>
        </FormControl>

        {/* Conditionally Render DatePicker */}
        <div style={{ flex: 1 }}>
          {selectedOption === 'month' ? (
            <DatePicker
              views={['year', 'month']}
              label="Select Month"
              value={startDate}
              onChange={(date) => handleDateChange(date, true)}
              renderInput={(params) => (
                <TextField {...params} fullWidth style={{ minWidth: '200px', fontSize: '1.2em' }} />
              )}
            />
          ) : (
            <DatePicker
              label="Select Date"
              value={startDate}
              onChange={(date) => handleDateChange(date, true)}
              renderInput={(params) => (
                <TextField {...params} fullWidth style={{ minWidth: '200px', fontSize: '1.2em' }} />
              )}
            />
          )}
        </div>

        {/* Project Selection */}
        <FormControl style={{ width: '100%', maxWidth: '400px' }}>
          <InputLabel id="project-select-label">Select Project</InputLabel>
          <Select
            labelId="project-select-label"
            className="capitalize"
            value={selectedProject || ""}
            onChange={handleProjectChange}
            label="Select Project"
          >
            {activeProjects.map((project) => (
              <MenuItem key={project.project_id} value={project.project_id}>
                {project.project_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Navigation Buttons */}
        <div className="flex space-x-2">
          <Button variant="outlined" onClick={() => adjustDates('prev')}>
            Previous
          </Button>
          <Button variant="outlined" onClick={() => adjustDates('next')}>
            Next 
          </Button>
        </div>

        {/* Export CSV Button */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleExportCSV}
          disabled={!selectedProject || !Object.keys(timesheetData).length}
        >
          Export CSV
        </Button>
      </div>
    
    
        {/* Render Charts */}
        {renderCharts()}
      
        {/* Display Total Hours if there is data */}
{Object.keys(timesheetData).length > 0 && (
  <div className="mt-4 p-4 flex justify-center">
    <h2 className="sticky top-0 mb-4 text-3xl font-bold text-gray-900 dark:text-black md:text-5xl lg:text-5xl">
            <span className="text-transparent bg-clip-text bg-gray-900">
              Overall Time: 
            </span>{" "}
            {totalHours.toFixed(2)} hours
          </h2>
    {/* <p className="text-3xl font-bold ml-2 text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
      {totalHours.toFixed(2)} hours
    </p> */}
  </div>
)}



        {/* View Selection */}
        <div className="mt-8">
          <div className="flex gap-4 mb-4">
            <p
              className={`border-2 rounded-2xl px-6 py-2 cursor-pointer ${
                selectedView === 'project'
                  ? 'bg-orange-600 text-white'
                  : 'border-orange-600 text-orange-600'
              }`}
              onClick={() => setSelectedView('project')}
            >
              Individual View
            </p>
            {/* <p
              className={`border-2 border-green-500 rounded-2xl px-6 py-2 cursor-pointer ${
                selectedView === 'timeline'
                  ? 'bg-green-600 text-white'
                  : 'border-green-500 text-green-500'
              }`}
              onClick={() => setSelectedView('timeline')}
            >
              Timeline
            </p> */}
            <p
              className={`border-2 border-red-500 rounded-2xl px-6 py-2 cursor-pointer ${
                selectedView === 'task'
                  ? 'bg-red-600 text-white'
                  : 'border-red-500 text-red-500'
              }`}
              onClick={() => setSelectedView('task')}
            >
              Task View
            </p>
          </div>

          {/* Conditionally render views based on selectedView */}
          {selectedView === 'project' && <TimeSheetTable data={timesheetData} />}
          {selectedView === 'timeline' && <TimelineComponent data={timesheetData} />}
        
          {selectedView === 'task' && <TaskViewTable data={timesheetData} />}
        </div>
        </div>
      </StyledContainer>
    );
  };

export default ProjectAnalysis;

// TimeSheetTable Component
const TimeSheetTable = ({ data }) => {
  // if (!data || Object.keys(data).length === 0) {
  //   return <p>No timesheet data available.</p>;
  // }

  return (
    <div className="p-4">
      {Object.keys(data).map((projectId) => {
        const project = data[projectId];
        return (
          <div key={projectId} className="mb-8">
            <h2 className="text-xl font-semibold mb-4">{project.project_name}</h2>
            {Object.keys(project.users).map((userId) => {
              const user = project.users[userId];

              // Calculate total hours for the user
              const totalHours = Object.keys(user.hours).reduce((total, taskId) => {
                return total + parseFloat(user.hours[taskId].hour);
              }, 0);

              return (
                <div key={userId} className="mb-4">
                  <h3 className="text-lg font-medium mb-2">{user.name}</h3>
                  <table className="min-w-full table-auto border-collapse border border-gray-300 table-fixed">
                    <thead>
                      <tr className="bg-gray-200"> {/* Switched to bg-gray-200 */}
                        <th className="border border-gray-300 px-4 py-2 text-left w-3/4">
                          Task Name
                        </th>
                        <th className="border border-gray-300 px-4 py-2 text-left w-1/4">
                          Hours
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(user.hours).map((taskId, index) => {
                        const task = user.hours[taskId];
                        return (
                          <tr
                            key={taskId}
                            className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                          >
                            <td className="border border-gray-300 px-4 py-2">
                              {task.name}
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                              {task.hour}
                            </td>
                          </tr>
                        );
                      })}
                      {/* Total Row */}
                      <tr className="bg-gray-100 font-semibold"> {/* Switched to bg-gray-100 */}
                        <td className="border border-gray-300 px-4 py-2">Total</td>
                        <td className="border border-gray-300 px-4 py-2">{totalHours.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};


// TaskViewTable Component

const TaskViewTable = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return <p>No task data available.</p>;
  }

  const tasksMap = {};
  const userHoursMap = {};

  const projectIds = Object.keys(data);
  if (projectIds.length === 0) return null;

  const project = data[projectIds[0]];
  const users = project.users;

  Object.keys(users).forEach((userId) => {
    const user = users[userId];
    Object.keys(user.hours).forEach((taskId) => {
      const task = user.hours[taskId];
      const shortTaskName = getShortNameForTask(task.name); // Use the imported function here
      if (tasksMap[taskId]) {
        tasksMap[taskId].hours += task.hour;
      } else {
        tasksMap[taskId] = { name: shortTaskName, fullName: task.name, hours: task.hour }; // Store full name
      }

      if (!userHoursMap[userId]) {
        userHoursMap[userId] = { name: user.name, tasks: {}, total: 0 };
      }
      userHoursMap[userId].tasks[taskId] = (userHoursMap[userId].tasks[taskId] || 0) + task.hour;
      userHoursMap[userId].total += task.hour;
    });
  });

  const taskIds = Object.keys(tasksMap);
  const taskNames = taskIds.map((taskId) => tasksMap[taskId]);

  return (
    <div className="p-4 overflow-x">
      <h2 className="text-xl font-semibold mb-4">Task Distribution</h2>
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left">Name</th>
            <th className="border border-gray-300 px-4 py-2 text-left bg-yellow-200 font-bold">Total</th> {/* Highlighted Total */}
            {taskNames.map((task, index) => (
              <th key={index} className="border border-gray-300 px-4 py-2 text-left">
                <div className="tooltip">
                  {task.name}
                  <span className="tooltiptext">{task.fullName}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(userHoursMap).map((userId) => {
            const user = userHoursMap[userId];
            return (
              <tr key={userId} className="bg-white odd:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2">{user.name}</td>
                <td className="border border-gray-300 px-4 py-2 bg-yellow-200 font-bold">{user.total}</td> {/* Highlighted Total */}
                {taskIds.map((taskId) => (
                  <td key={taskId} className="border border-gray-300 px-4 py-2">
                    {user.tasks[taskId] || 0}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};


// Placeholder for TimelineComponent
const TimelineComponent = ({ data }) => {
  // Implement your timeline view here
  return (
    <div className="p-4">
      <p>Timeline view is under construction.</p>
    </div>
  );
};

import React from "react";
import { TbProgress } from "react-icons/tb";
import { RiLoader3Line } from "react-icons/ri";
import { RiLoader4Fill } from "react-icons/ri";
function ProgressLoader() {
  return (
    <div className="fixed left-0 right-0  top-0 bottom-0 flex justify-center items-center z-40 bg-gray-800/80">
      <TbProgress className="animate-spin text-8xl font-black  text-[#00AAFF]" />
    </div>
  );
}

export default ProgressLoader;